.form-group {
    margin-bottom: 1.5rem;
}

.form {
    width: 500px;
    max-width: 100%;
}


.form-label-light {
    color: $text-muted;
}

.form-control-light {
    border-color: $gray-200;
}