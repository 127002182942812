.animation-pulse {
	animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
	0% {
	  -moz-box-shadow: 0 0 0 0 $danger;
	  box-shadow: 0 0 0 0 $danger;
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
		box-shadow: 0 0 0 10px rgba(204,169,44, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
		box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
  }