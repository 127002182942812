/* Faded Alert */
@each $color, $value in $theme-colors {
    .alert-#{$color}-faded {
        @include alert-variant-faded(rgba($value, $alert-faded-bg-opacity), $value);
    }
}

@each $color, $value in $theme-colors {
    .alert-#{$color} {
        background-color: transparentize($value, .85);
        border-color: $value;
        color: white;
    }
}
