@charset "UTF-8";
/* Table of contents
––––––––––––––––––––––––––––––––––––––––––––––––––

Note: Do not write any CSS in this file. Only use it to import other CSS files.
Note: '~' in the import statement means the file is imported from the node_modules directory. Bootstrap functions is an example.

- Bootstrap Functions.
- Bootstrap Mixins.
- Framework SASS Variables - override Bootstrap's SASS Vars in this file.
- Framework Mixins
- Framework Utilities
- Bootstrap Core
- Framework CSS Custom Properties (CSS Variables)
- Framework Base
- Framework Components
- Framework Vendors
- Custom CSS - Add your custom CSS to this file
*/
/* Bootstrap Functions - DO NOT CHANGE ANYTHING IN HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Framework SASS Variables - GO HERE TO OVERRIDE BOOTSTRAP VARS.
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Bootstrap Maps - DO NOT CHANGE ANYTHING IN HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Bootstrap Mixins - DO NOT CHANGE ANYTHING IN HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Framework Mixins
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Alert */ /* Mixin faded alert */
/* Backgrounds */
/* Mixin background faded */
/* Buttons */
/* Mixin button faded */
/* Framework Utilities - Needs to be imported before main bootstrap file.
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/**
* - Everything in "bootstrap-api-utilities" either adds to or amends the Bootstrap utilities using their API
* - Everything else is normal CSS/SCSS
*/
/* Bootstrap API Utilities - only put code in here that uses Bootstrap's Util API */
/* Everything in these files DOES NOT use Bootstrap's API */
/* Animations */
.animation-pulse {
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #f44336;
    box-shadow: 0 0 0 0 #f44336;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
/* Backgrounds */
.bg-primary-faded {
  background-color: rgba(30, 136, 229, 0.5) !important;
}

.bg-secondary-faded {
  background-color: rgba(98, 104, 116, 0.5) !important;
}

.bg-success-faded {
  background-color: rgba(76, 175, 80, 0.5) !important;
}

.bg-info-faded {
  background-color: rgba(0, 188, 212, 0.5) !important;
}

.bg-warning-faded {
  background-color: rgba(255, 193, 7, 0.5) !important;
}

.bg-danger-faded {
  background-color: rgba(244, 67, 54, 0.5) !important;
}

.bg-light-faded {
  background-color: rgba(124, 129, 139, 0.5) !important;
}

.bg-dark-faded {
  background-color: rgba(30, 41, 59, 0.5) !important;
}

.bg-facebook-faded {
  background-color: rgba(59, 89, 152, 0.5) !important;
}

.bg-twitter-faded {
  background-color: rgba(0, 172, 238, 0.5) !important;
}

.bg-instagram-faded {
  background-color: rgba(225, 48, 108, 0.5) !important;
}

.bg-white-faded {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.bg-pink-faded {
  background-color: rgba(233, 30, 99, 0.5) !important;
}

.bg-teal-faded {
  background-color: rgba(0, 150, 136, 0.5) !important;
}

.bg-orange-faded {
  background-color: rgba(255, 87, 34, 0.5) !important;
}

/* custom bg colours */
.bg-dark-700 {
  background: #484e5d !important;
}

.bg-dark-800 {
  background: #1e293b !important;
}

.bg-dark-900 {
  background: #141c2f !important;
}

.bg-dark-opacity {
  background: var(--theme-bg-dark-opacity);
}

.carousel-item::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.35) 50%);
  z-index: 10;
  pointer-events: none;
}
.carousel-item .carousel-caption, .carousel-item .carousel-control-prev, .carousel-item .carousel-control-next, .carousel-item .carousel-indicators {
  z-index: 20;
}

/* Misc */
.transition-color {
  transition: color ease-in 0.2s;
}

.dot {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
}

.dot-sm {
  width: 0.75rem;
  height: 0.75rem;
}

.dot-xs {
  width: 0.475rem;
  height: 0.475rem;
}

/* Text */
@media (max-width: 767.98px) {
  .display-md-1 {
    font-size: calc(1.5rem + 3vw);
    font-weight: 300;
    line-height: 1.2;
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .display-md-1 {
    font-size: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .display-md-2 {
    font-size: calc(1.45rem + 2.4vw);
    font-weight: 300;
    line-height: 1.2;
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .display-md-2 {
    font-size: 3.25rem;
  }
}
@media (max-width: 767.98px) {
  .display-md-3 {
    font-size: calc(1.4rem + 1.8vw);
    font-weight: 300;
    line-height: 1.2;
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .display-md-3 {
    font-size: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .display-md-4 {
    font-size: calc(1.35rem + 1.2vw);
    font-weight: 300;
    line-height: 1.2;
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .display-md-4 {
    font-size: 2.25rem;
  }
}
@media (max-width: 767.98px) {
  .display-md-5 {
    font-size: calc(1.3rem + 0.6vw);
    font-weight: 300;
    line-height: 1.2;
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .display-md-5 {
    font-size: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .display-md-6 {
    font-size: calc(1.275rem + 0.3vw);
    font-weight: 300;
    line-height: 1.2;
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .display-md-6 {
    font-size: 1.5rem;
  }
}
.fs-xs {
  font-size: 11px !important;
}

/* p and fs-p */
p,
.fs-p {
  font-size: 0.975rem;
}

/* text link cover full area. Used mostly with cards */
.link-cover:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  pointer-events: auto;
  content: "";
}

.text-link-border {
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  border-radius: 0;
  transition: all linear 0.2s;
}
.text-link-border:hover, .text-link-border:focus, .text-link-border:active {
  border-color: transparent;
}

.title-small {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: 500;
  display: block;
}

/* Bootstrap Core - DO NOT CHANGE ANYTHING IN HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*!
 * Bootstrap  v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #1e88e5;
  --bs-indigo: #3949ab;
  --bs-purple: #9c27b0;
  --bs-pink: #e91e63;
  --bs-red: #f44336;
  --bs-orange: #ff5722;
  --bs-yellow: #ffc107;
  --bs-green: #4caf50;
  --bs-teal: #009688;
  --bs-cyan: #00bcd4;
  --bs-white: #fff;
  --bs-gray: #626874;
  --bs-gray-dark: #1e293b;
  --bs-gray-100: #e5e6e8;
  --bs-gray-200: #cbcdd1;
  --bs-gray-300: #b1b3ba;
  --bs-gray-400: #979aa3;
  --bs-gray-500: #7c818b;
  --bs-gray-600: #626874;
  --bs-gray-700: #484e5d;
  --bs-gray-800: #1e293b;
  --bs-gray-900: #141c2f;
  --bs-primary: #1e88e5;
  --bs-secondary: #626874;
  --bs-success: #4caf50;
  --bs-info: #00bcd4;
  --bs-warning: #ffc107;
  --bs-danger: #f44336;
  --bs-light: #7c818b;
  --bs-dark: #1e293b;
  --bs-facebook: #3b5998;
  --bs-twitter: #00acee;
  --bs-instagram: #E1306C;
  --bs-white: #fff;
  --bs-pink: #e91e63;
  --bs-teal: #009688;
  --bs-orange: #ff5722;
  --bs-primary-rgb: 30, 136, 229;
  --bs-secondary-rgb: 98, 104, 116;
  --bs-success-rgb: 76, 175, 80;
  --bs-info-rgb: 0, 188, 212;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 244, 67, 54;
  --bs-light-rgb: 124, 129, 139;
  --bs-dark-rgb: 30, 41, 59;
  --bs-facebook-rgb: 59, 89, 152;
  --bs-twitter-rgb: 0, 172, 238;
  --bs-instagram-rgb: 225, 48, 108;
  --bs-white-rgb: 255, 255, 255;
  --bs-pink-rgb: 233, 30, 99;
  --bs-teal-rgb: 0, 150, 136;
  --bs-orange-rgb: 255, 87, 34;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg-rgb: 30, 41, 59;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.975rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: rgba(255, 255, 255, 0.75);
  --bs-body-bg: #1e293b;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: rgba(255, 255, 255, 0.08);
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.2rem;
  --bs-border-radius-lg: 0.3rem;
  --bs-border-radius-xl: 20px;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #1e88e5;
  --bs-link-hover-color: #186db7;
  --bs-code-color: #e91e63;
  --bs-highlight-bg: #fcf8e3;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.36875rem + 1.425vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.4375rem;
  }
}

h2, .h2 {
  font-size: calc(1.32rem + 0.84vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.95rem;
  }
}

h3, .h3 {
  font-size: calc(1.295625rem + 0.5475vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.70625rem;
  }
}

h4, .h4 {
  font-size: calc(1.27125rem + 0.255vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.4625rem;
  }
}

h5, .h5 {
  font-size: 1.21875rem;
}

h6, .h6 {
  font-size: 0.975rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 600;
}

small, .small {
  font-size: 0.85em;
}

mark, .mark {
  padding: 0.2em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.85em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.85em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.85em;
  color: #fff;
  background-color: #141c2f;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #7c818b;
  text-align: left;
}

th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.21875rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.35rem + 1.2vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.25rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.85em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.21875rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.85em;
  color: #626874;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #1e293b;
  border: 1px solid #b1b3ba;
  border-radius: 0.2rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.85em;
  color: #626874;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 3.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 3.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 4rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 4rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 6rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 6rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 7rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 7rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 3.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 3.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 7rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 3.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 3.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 4rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 4rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 6rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 6rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 7rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 3.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 3.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 7rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 7rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 7rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 7rem;
  }
}
.table {
  --bs-table-color: rgba(255, 255, 255, 0.75);
  --bs-table-bg: transparent;
  --bs-table-border-color: rgba(255, 255, 255, 0.08);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: rgba(255, 255, 255, 0.75);
  --bs-table-striped-bg: #141c2f;
  --bs-table-active-color: rgba(255, 255, 255, 0.75);
  --bs-table-active-bg: #fff;
  --bs-table-hover-color: rgba(255, 255, 255, 0.75);
  --bs-table-hover-bg: #cbcdd1;
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: middle;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d2e7fa;
  --bs-table-border-color: #bdd0e1;
  --bs-table-striped-bg: #c8dbee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdd0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2d6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e0e1e3;
  --bs-table-border-color: #cacbcc;
  --bs-table-striped-bg: #d5d6d8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cacbcc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfd0d2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #dbefdc;
  --bs-table-border-color: #c5d7c6;
  --bs-table-striped-bg: #d0e3d1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5d7c6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbddcc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #ccf2f6;
  --bs-table-border-color: #b8dadd;
  --bs-table-striped-bg: #c2e6ea;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8dadd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bde0e4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #fdd9d7;
  --bs-table-border-color: #e4c3c2;
  --bs-table-striped-bg: #f0cecc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4c3c2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eac9c7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #7c818b;
  --bs-table-border-color: #70747d;
  --bs-table-striped-bg: #767b84;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #70747d;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #737781;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #1e293b;
  --bs-table-border-color: #353e4f;
  --bs-table-striped-bg: #293445;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #353e4f;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #2f394a;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}

.col-form-label {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
}

.col-form-label-lg {
  padding-top: calc(1.5rem + 1px);
  padding-bottom: calc(1.5rem + 1px);
  font-size: 1.21875rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.853125rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.85em;
  color: #7c818b;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.65rem 0.75rem;
  font-size: 0.85em;
  font-weight: 400;
  line-height: 1.5;
  color: #e5e6e8;
  background-color: #1e293b;
  background-clip: padding-box;
  border: 1px solid #484e5d;
  appearance: none;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #e5e6e8;
  background-color: #1e293b;
  border-color: #1e88e5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #cbcdd1;
  opacity: 1;
}
.form-control:disabled {
  background-color: #484e5d;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.65rem 0.75rem;
  margin: -0.65rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #e5e6e8;
  background-color: #626874;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #5d636e;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.65rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.853125rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 3rem + 2px);
  padding: 1.5rem 2rem;
  font-size: 1.21875rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 1.5rem 2rem;
  margin: -1.5rem -2rem;
  margin-inline-end: 2rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.3rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 3rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.2rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.2rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 3rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.65rem 1rem 0.65rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.85em;
  font-weight: 400;
  line-height: 1.5;
  color: #e5e6e8;
  background-color: #1e293b;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23cbcdd1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #484e5d;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #1e88e5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  color: #979aa3;
  background-color: #484e5d;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #e5e6e8;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.853125rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  font-size: 1.21875rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.4625rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #626874;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #626874;
  appearance: none;
  print-color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #1e88e5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.form-check-input:checked {
  background-color: #1e88e5;
  border-color: #1e88e5;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: transparent;
  border-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%231e88e5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231e88e5'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.6%29'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #1e293b, 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #1e293b, 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1e88e5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: rgba(255, 255, 255, 0.7);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1e88e5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: rgba(255, 255, 255, 0.7);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #7c818b;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #7c818b;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.65rem 0.75rem;
  font-size: 0.85em;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  background-color: #626874;
  border: 1px solid transparent;
  border-radius: 0.2rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1.5rem 2rem;
  font-size: 1.21875rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.853125rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.85em;
  color: #4caf50;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.853125rem;
  color: #000;
  background-color: rgba(76, 175, 80, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #4caf50;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234caf50' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.25rem rgba(76, 175, 80, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.3rem);
  background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #4caf50;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: calc(0.75em + 2.725rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23cbcdd1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234caf50' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.25rem rgba(76, 175, 80, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.3rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #4caf50;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #4caf50;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(76, 175, 80, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4caf50;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.85em;
  color: #f44336;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.853125rem;
  color: #000;
  background-color: rgba(244, 67, 54, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f44336;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f44336'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f44336' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.25rem rgba(244, 67, 54, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.3rem);
  background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #f44336;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: calc(0.75em + 2.725rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23cbcdd1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f44336'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f44336' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.25rem rgba(244, 67, 54, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.3rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #f44336;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #f44336;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 67, 54, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f44336;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.15rem;
  --bs-btn-padding-y: 0.98rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.975rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1;
  --bs-btn-color: rgba(255, 255, 255, 0.75);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #1e88e5;
  --bs-btn-border-color: #1e88e5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #409ae9;
  --bs-btn-hover-border-color: #3594e8;
  --bs-btn-focus-shadow-rgb: 26, 116, 195;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4ba0ea;
  --bs-btn-active-border-color: #3594e8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #1e88e5;
  --bs-btn-disabled-border-color: #1e88e5;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #626874;
  --bs-btn-border-color: #626874;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #535863;
  --bs-btn-hover-border-color: #4e535d;
  --bs-btn-focus-shadow-rgb: 122, 127, 137;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4e535d;
  --bs-btn-active-border-color: #4a4e57;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #626874;
  --bs-btn-disabled-border-color: #626874;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #4caf50;
  --bs-btn-border-color: #4caf50;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #67bb6a;
  --bs-btn-hover-border-color: #5eb762;
  --bs-btn-focus-shadow-rgb: 65, 149, 68;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #70bf73;
  --bs-btn-active-border-color: #5eb762;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #4caf50;
  --bs-btn-disabled-border-color: #4caf50;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #00bcd4;
  --bs-btn-border-color: #00bcd4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #26c6da;
  --bs-btn-hover-border-color: #1ac3d8;
  --bs-btn-focus-shadow-rgb: 0, 160, 180;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #33c9dd;
  --bs-btn-active-border-color: #1ac3d8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #00bcd4;
  --bs-btn-disabled-border-color: #00bcd4;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #f44336;
  --bs-btn-border-color: #f44336;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f65f54;
  --bs-btn-hover-border-color: #f5564a;
  --bs-btn-focus-shadow-rgb: 207, 57, 46;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f6695e;
  --bs-btn-active-border-color: #f5564a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f44336;
  --bs-btn-disabled-border-color: #f44336;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #7c818b;
  --bs-btn-border-color: #7c818b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #696e76;
  --bs-btn-hover-border-color: #63676f;
  --bs-btn-focus-shadow-rgb: 105, 110, 118;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #63676f;
  --bs-btn-active-border-color: #5d6168;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #7c818b;
  --bs-btn-disabled-border-color: #7c818b;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1e293b;
  --bs-btn-border-color: #1e293b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #404958;
  --bs-btn-hover-border-color: #353e4f;
  --bs-btn-focus-shadow-rgb: 64, 73, 88;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4b5462;
  --bs-btn-active-border-color: #353e4f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1e293b;
  --bs-btn-disabled-border-color: #1e293b;
}

.btn-facebook {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3b5998;
  --bs-btn-border-color: #3b5998;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #324c81;
  --bs-btn-hover-border-color: #2f477a;
  --bs-btn-focus-shadow-rgb: 88, 114, 167;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2f477a;
  --bs-btn-active-border-color: #2c4372;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3b5998;
  --bs-btn-disabled-border-color: #3b5998;
}

.btn-twitter {
  --bs-btn-color: #000;
  --bs-btn-bg: #00acee;
  --bs-btn-border-color: #00acee;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #26b8f1;
  --bs-btn-hover-border-color: #1ab4f0;
  --bs-btn-focus-shadow-rgb: 0, 146, 202;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #33bdf1;
  --bs-btn-active-border-color: #1ab4f0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #00acee;
  --bs-btn-disabled-border-color: #00acee;
}

.btn-instagram {
  --bs-btn-color: #000;
  --bs-btn-bg: #E1306C;
  --bs-btn-border-color: #E1306C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e64f82;
  --bs-btn-hover-border-color: #e4457b;
  --bs-btn-focus-shadow-rgb: 191, 41, 92;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e75989;
  --bs-btn-active-border-color: #e4457b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #E1306C;
  --bs-btn-disabled-border-color: #E1306C;
}

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}

.btn-pink {
  --bs-btn-color: #000;
  --bs-btn-bg: #e91e63;
  --bs-btn-border-color: #e91e63;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ec407a;
  --bs-btn-hover-border-color: #eb3573;
  --bs-btn-focus-shadow-rgb: 198, 26, 84;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ed4b82;
  --bs-btn-active-border-color: #eb3573;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e91e63;
  --bs-btn-disabled-border-color: #e91e63;
}

.btn-teal {
  --bs-btn-color: #000;
  --bs-btn-bg: #009688;
  --bs-btn-border-color: #009688;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #26a69a;
  --bs-btn-hover-border-color: #1aa194;
  --bs-btn-focus-shadow-rgb: 0, 128, 116;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #33aba0;
  --bs-btn-active-border-color: #1aa194;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #009688;
  --bs-btn-disabled-border-color: #009688;
}

.btn-orange {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff5722;
  --bs-btn-border-color: #ff5722;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff7043;
  --bs-btn-hover-border-color: #ff6838;
  --bs-btn-focus-shadow-rgb: 217, 74, 29;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff794e;
  --bs-btn-active-border-color: #ff6838;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ff5722;
  --bs-btn-disabled-border-color: #ff5722;
}

.btn-outline-primary {
  --bs-btn-color: #1e88e5;
  --bs-btn-border-color: #1e88e5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #1e88e5;
  --bs-btn-hover-border-color: #1e88e5;
  --bs-btn-focus-shadow-rgb: 30, 136, 229;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #1e88e5;
  --bs-btn-active-border-color: #1e88e5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1e88e5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1e88e5;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #626874;
  --bs-btn-border-color: #626874;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #626874;
  --bs-btn-hover-border-color: #626874;
  --bs-btn-focus-shadow-rgb: 98, 104, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #626874;
  --bs-btn-active-border-color: #626874;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #626874;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #626874;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #4caf50;
  --bs-btn-border-color: #4caf50;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #4caf50;
  --bs-btn-hover-border-color: #4caf50;
  --bs-btn-focus-shadow-rgb: 76, 175, 80;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4caf50;
  --bs-btn-active-border-color: #4caf50;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4caf50;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4caf50;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #00bcd4;
  --bs-btn-border-color: #00bcd4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #00bcd4;
  --bs-btn-hover-border-color: #00bcd4;
  --bs-btn-focus-shadow-rgb: 0, 188, 212;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #00bcd4;
  --bs-btn-active-border-color: #00bcd4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00bcd4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00bcd4;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #f44336;
  --bs-btn-border-color: #f44336;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f44336;
  --bs-btn-hover-border-color: #f44336;
  --bs-btn-focus-shadow-rgb: 244, 67, 54;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f44336;
  --bs-btn-active-border-color: #f44336;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f44336;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f44336;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #7c818b;
  --bs-btn-border-color: #7c818b;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7c818b;
  --bs-btn-hover-border-color: #7c818b;
  --bs-btn-focus-shadow-rgb: 124, 129, 139;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #7c818b;
  --bs-btn-active-border-color: #7c818b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7c818b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7c818b;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #1e293b;
  --bs-btn-border-color: #1e293b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1e293b;
  --bs-btn-hover-border-color: #1e293b;
  --bs-btn-focus-shadow-rgb: 30, 41, 59;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1e293b;
  --bs-btn-active-border-color: #1e293b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1e293b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1e293b;
  --bs-gradient: none;
}

.btn-outline-facebook {
  --bs-btn-color: #3b5998;
  --bs-btn-border-color: #3b5998;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3b5998;
  --bs-btn-hover-border-color: #3b5998;
  --bs-btn-focus-shadow-rgb: 59, 89, 152;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3b5998;
  --bs-btn-active-border-color: #3b5998;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3b5998;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3b5998;
  --bs-gradient: none;
}

.btn-outline-twitter {
  --bs-btn-color: #00acee;
  --bs-btn-border-color: #00acee;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #00acee;
  --bs-btn-hover-border-color: #00acee;
  --bs-btn-focus-shadow-rgb: 0, 172, 238;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #00acee;
  --bs-btn-active-border-color: #00acee;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00acee;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00acee;
  --bs-gradient: none;
}

.btn-outline-instagram {
  --bs-btn-color: #E1306C;
  --bs-btn-border-color: #E1306C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #E1306C;
  --bs-btn-hover-border-color: #E1306C;
  --bs-btn-focus-shadow-rgb: 225, 48, 108;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #E1306C;
  --bs-btn-active-border-color: #E1306C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #E1306C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #E1306C;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none;
}

.btn-outline-pink {
  --bs-btn-color: #e91e63;
  --bs-btn-border-color: #e91e63;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e91e63;
  --bs-btn-hover-border-color: #e91e63;
  --bs-btn-focus-shadow-rgb: 233, 30, 99;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e91e63;
  --bs-btn-active-border-color: #e91e63;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e91e63;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e91e63;
  --bs-gradient: none;
}

.btn-outline-teal {
  --bs-btn-color: #009688;
  --bs-btn-border-color: #009688;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #009688;
  --bs-btn-hover-border-color: #009688;
  --bs-btn-focus-shadow-rgb: 0, 150, 136;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #009688;
  --bs-btn-active-border-color: #009688;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #009688;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #009688;
  --bs-gradient: none;
}

.btn-outline-orange {
  --bs-btn-color: #ff5722;
  --bs-btn-border-color: #ff5722;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff5722;
  --bs-btn-hover-border-color: #ff5722;
  --bs-btn-focus-shadow-rgb: 255, 87, 34;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff5722;
  --bs-btn-active-border-color: #ff5722;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff5722;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff5722;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #1e88e5;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #186db7;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #186db7;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #626874;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 26, 116, 195;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 1.5rem;
  --bs-btn-padding-x: 2rem;
  --bs-btn-font-size: 1.21875rem;
  --bs-btn-border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.45rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.853125rem;
  --bs-btn-border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0.75rem;
  --bs-dropdown-padding-y: 1rem;
  --bs-dropdown-spacer: 0.45rem;
  --bs-dropdown-font-size: 0.85rem;
  --bs-dropdown-color: #7c818b;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: transparent;
  --bs-dropdown-border-radius: 0.25rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.25rem - 1px);
  --bs-dropdown-divider-bg: #e5e6e8;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125);
  --bs-dropdown-link-color: #7c818b;
  --bs-dropdown-link-hover-color: #1e88e5;
  --bs-dropdown-link-hover-bg: transparent;
  --bs-dropdown-link-active-color: #1e88e5;
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-link-disabled-color: #626874;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0;
  --bs-dropdown-header-color: #626874;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 1rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.853125rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #b1b3ba;
  --bs-dropdown-bg: #1e293b;
  --bs-dropdown-border-color: transparent;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #b1b3ba;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #e5e6e8;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #1e88e5;
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-link-disabled-color: #7c818b;
  --bs-dropdown-header-color: #7c818b;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.25rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.8625rem;
  padding-left: 0.8625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-size: 0.875rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: rgba(255, 255, 255, 0.75);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #626874;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #b1b3ba;
  --bs-nav-tabs-border-radius: 0.25rem;
  --bs-nav-tabs-link-hover-border-color: #cbcdd1 #cbcdd1 #b1b3ba;
  --bs-nav-tabs-link-active-color: #484e5d;
  --bs-nav-tabs-link-active-bg: #1e293b;
  --bs-nav-tabs-link-active-border-color: #b1b3ba #b1b3ba #1e293b;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 50rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #1e88e5;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3171875rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.21875rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.21875rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.25rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-size: 0.875rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: white;
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 0.25rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: rgba(255, 255, 255, 0.08);
  --bs-card-border-radius: 0.25rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 0.25rem;
  --bs-card-cap-padding-y: 1.25rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #141c2f;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: rgba(255, 255, 255, 0.75);
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: rgba(255, 255, 255, 0.08);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.25rem;
  --bs-accordion-inner-border-radius: calc(0.25rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: rgba(255, 255, 255, 0.75);
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.75%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 0.75rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #1e88e5;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #fff;
  --bs-accordion-active-bg: #1e88e5;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.975rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-font-size: 0.875rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #7c818b;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #7c818b;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 1rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.975rem;
  --bs-pagination-color: rgba(255, 255, 255, 0.75);
  --bs-pagination-bg: transparent;
  --bs-pagination-border-width: 0;
  --bs-pagination-border-color: transparent;
  --bs-pagination-border-radius: 0.25rem;
  --bs-pagination-hover-color: #186db7;
  --bs-pagination-hover-bg: #cbcdd1;
  --bs-pagination-hover-border-color: #b1b3ba;
  --bs-pagination-focus-color: #186db7;
  --bs-pagination-focus-bg: #cbcdd1;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #1e88e5;
  --bs-pagination-active-border-color: #1e88e5;
  --bs-pagination-disabled-color: #626874;
  --bs-pagination-disabled-bg: transparent;
  --bs-pagination-disabled-border-color: #b1b3ba;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.21875rem;
  --bs-pagination-border-radius: 0.3rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.853125rem;
  --bs-pagination-border-radius: 0.2rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.2rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #125289;
  --bs-alert-bg: #d2e7fa;
  --bs-alert-border-color: #a5cff5;
}
.alert-primary .alert-link {
  color: #0e426e;
}

.alert-secondary {
  --bs-alert-color: #3b3e46;
  --bs-alert-bg: #e0e1e3;
  --bs-alert-border-color: #c0c3c7;
}
.alert-secondary .alert-link {
  color: #2f3238;
}

.alert-success {
  --bs-alert-color: #2e6930;
  --bs-alert-bg: #dbefdc;
  --bs-alert-border-color: #b7dfb9;
}
.alert-success .alert-link {
  color: #255426;
}

.alert-info {
  --bs-alert-color: #00717f;
  --bs-alert-bg: #ccf2f6;
  --bs-alert-border-color: #99e4ee;
}
.alert-info .alert-link {
  color: #005a66;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffe69c;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #922820;
  --bs-alert-bg: #fdd9d7;
  --bs-alert-border-color: #fbb4af;
}
.alert-danger .alert-link {
  color: #75201a;
}

.alert-light {
  --bs-alert-color: #4a4d53;
  --bs-alert-bg: #e5e6e8;
  --bs-alert-border-color: #cbcdd1;
}
.alert-light .alert-link {
  color: #3b3e42;
}

.alert-dark {
  --bs-alert-color: #121923;
  --bs-alert-bg: #d2d4d8;
  --bs-alert-border-color: #a5a9b1;
}
.alert-dark .alert-link {
  color: #0e141c;
}

.alert-facebook {
  --bs-alert-color: #23355b;
  --bs-alert-bg: #d8deea;
  --bs-alert-border-color: #b1bdd6;
}
.alert-facebook .alert-link {
  color: #1c2a49;
}

.alert-twitter {
  --bs-alert-color: #00678f;
  --bs-alert-bg: #cceefc;
  --bs-alert-border-color: #99def8;
}
.alert-twitter .alert-link {
  color: #005272;
}

.alert-instagram {
  --bs-alert-color: #871d41;
  --bs-alert-bg: #f9d6e2;
  --bs-alert-border-color: #f3acc4;
}
.alert-instagram .alert-link {
  color: #6c1734;
}

.alert-white {
  --bs-alert-color: #666666;
  --bs-alert-bg: white;
  --bs-alert-border-color: white;
}
.alert-white .alert-link {
  color: #525252;
}

.alert-pink {
  --bs-alert-color: #8c123b;
  --bs-alert-bg: #fbd2e0;
  --bs-alert-border-color: #f6a5c1;
}
.alert-pink .alert-link {
  color: #700e2f;
}

.alert-teal {
  --bs-alert-color: #005a52;
  --bs-alert-bg: #cceae7;
  --bs-alert-border-color: #99d5cf;
}
.alert-teal .alert-link {
  color: #004842;
}

.alert-orange {
  --bs-alert-color: #993414;
  --bs-alert-bg: #ffddd3;
  --bs-alert-border-color: #ffbca7;
}
.alert-orange .alert-link {
  color: #7a2a10;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.73125rem;
  --bs-progress-bg: rgba(255, 255, 255, 0.8);
  --bs-progress-border-radius: 0.25rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #1e88e5;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: rgba(255, 255, 255, 0.75);
  --bs-list-group-bg: transparent;
  --bs-list-group-border-color: rgba(255, 255, 255, 0.08);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.25rem;
  --bs-list-group-item-padding-x: 0;
  --bs-list-group-item-padding-y: 0.8rem;
  --bs-list-group-action-color: #484e5d;
  --bs-list-group-action-hover-color: #484e5d;
  --bs-list-group-action-hover-bg: #141c2f;
  --bs-list-group-action-active-color: rgba(255, 255, 255, 0.75);
  --bs-list-group-action-active-bg: #cbcdd1;
  --bs-list-group-disabled-color: #626874;
  --bs-list-group-disabled-bg: transparent;
  --bs-list-group-active-color: #1e88e5;
  --bs-list-group-active-bg: transparent;
  --bs-list-group-active-border-color: transparent;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #125289;
  background-color: #d2e7fa;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #125289;
  background-color: #bdd0e1;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #125289;
  border-color: #125289;
}

.list-group-item-secondary {
  color: #3b3e46;
  background-color: #e0e1e3;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #3b3e46;
  background-color: #cacbcc;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #3b3e46;
  border-color: #3b3e46;
}

.list-group-item-success {
  color: #2e6930;
  background-color: #dbefdc;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #2e6930;
  background-color: #c5d7c6;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #2e6930;
  border-color: #2e6930;
}

.list-group-item-info {
  color: #00717f;
  background-color: #ccf2f6;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #00717f;
  background-color: #b8dadd;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #00717f;
  border-color: #00717f;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #922820;
  background-color: #fdd9d7;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #922820;
  background-color: #e4c3c2;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #922820;
  border-color: #922820;
}

.list-group-item-light {
  color: #4a4d53;
  background-color: #e5e6e8;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #4a4d53;
  background-color: #cecfd1;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #4a4d53;
  border-color: #4a4d53;
}

.list-group-item-dark {
  color: #121923;
  background-color: #d2d4d8;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #121923;
  background-color: #bdbfc2;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #121923;
  border-color: #121923;
}

.list-group-item-facebook {
  color: #23355b;
  background-color: #d8deea;
}
.list-group-item-facebook.list-group-item-action:hover, .list-group-item-facebook.list-group-item-action:focus {
  color: #23355b;
  background-color: #c2c8d3;
}
.list-group-item-facebook.list-group-item-action.active {
  color: #fff;
  background-color: #23355b;
  border-color: #23355b;
}

.list-group-item-twitter {
  color: #00678f;
  background-color: #cceefc;
}
.list-group-item-twitter.list-group-item-action:hover, .list-group-item-twitter.list-group-item-action:focus {
  color: #00678f;
  background-color: #b8d6e3;
}
.list-group-item-twitter.list-group-item-action.active {
  color: #fff;
  background-color: #00678f;
  border-color: #00678f;
}

.list-group-item-instagram {
  color: #871d41;
  background-color: #f9d6e2;
}
.list-group-item-instagram.list-group-item-action:hover, .list-group-item-instagram.list-group-item-action:focus {
  color: #871d41;
  background-color: #e0c1cb;
}
.list-group-item-instagram.list-group-item-action.active {
  color: #fff;
  background-color: #871d41;
  border-color: #871d41;
}

.list-group-item-white {
  color: #666666;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-pink {
  color: #8c123b;
  background-color: #fbd2e0;
}
.list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
  color: #8c123b;
  background-color: #e2bdca;
}
.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #8c123b;
  border-color: #8c123b;
}

.list-group-item-teal {
  color: #005a52;
  background-color: #cceae7;
}
.list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
  color: #005a52;
  background-color: #b8d3d0;
}
.list-group-item-teal.list-group-item-action.active {
  color: #fff;
  background-color: #005a52;
  border-color: #005a52;
}

.list-group-item-orange {
  color: #993414;
  background-color: #ffddd3;
}
.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  color: #993414;
  background-color: #e6c7be;
}
.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #993414;
  border-color: #993414;
}

.btn-close {
  box-sizing: content-box;
  width: 0.65em;
  height: 0.65em;
  padding: 0.25em 0.25em;
  color: #141c2f;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23141c2f'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.65em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #141c2f;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: rgba(0, 0, 0, 0.1);
  --bs-toast-border-radius: 0.25rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(29, 48, 61, 0.06);
  --bs-toast-header-color: #626874;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1050;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.5rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: #1e293b;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: rgba(0, 0, 0, 0.2);
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: 0;
  --bs-modal-box-shadow: 0 4px 0.5rem rgba(29, 48, 61, 0.03);
  --bs-modal-inner-border-radius: 0;
  --bs-modal-header-padding-x: 1.5rem;
  --bs-modal-header-padding-y: 1.5rem;
  --bs-modal-header-padding: 1.5rem 1.5rem;
  --bs-modal-header-border-color: rgba(255, 255, 255, 0.08);
  --bs-modal-header-border-width: 0;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: rgba(255, 255, 255, 0.08);
  --bs-modal-footer-border-width: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1040;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(29, 48, 61, 0.06);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1070;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 1rem;
  --bs-tooltip-padding-y: 0.5rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: 0.853125rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #1e88e5;
  --bs-tooltip-border-radius: 0.25rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1060;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.853125rem;
  --bs-popover-bg: #1e88e5;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: rgba(255, 255, 255, 0.08);
  --bs-popover-border-radius: 0.3rem;
  --bs-popover-inner-border-radius: calc(0.3rem - 1px);
  --bs-popover-box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.975rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #1c80d7;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: rgba(255, 255, 255, 0.75);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1040;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1.5rem;
  --bs-offcanvas-padding-y: 1.5rem;
  --bs-offcanvas-color: #1e293b;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 0;
  --bs-offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --bs-offcanvas-box-shadow: 0 4px 0.5rem rgba(29, 48, 61, 0.03);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(30, 136, 229, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(98, 104, 116, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(76, 175, 80, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(0, 188, 212, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(244, 67, 54, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(124, 129, 139, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(30, 41, 59, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-facebook {
  color: #fff !important;
  background-color: RGBA(59, 89, 152, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-twitter {
  color: #000 !important;
  background-color: RGBA(0, 172, 238, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-instagram {
  color: #000 !important;
  background-color: RGBA(225, 48, 108, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #000 !important;
  background-color: RGBA(233, 30, 99, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal {
  color: #000 !important;
  background-color: RGBA(0, 150, 136, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange {
  color: #000 !important;
  background-color: RGBA(255, 87, 34, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #1e88e5 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #4ba0ea !important;
}

.link-secondary {
  color: #626874 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #4e535d !important;
}

.link-success {
  color: #4caf50 !important;
}
.link-success:hover, .link-success:focus {
  color: #70bf73 !important;
}

.link-info {
  color: #00bcd4 !important;
}
.link-info:hover, .link-info:focus {
  color: #33c9dd !important;
}

.link-warning {
  color: #ffc107 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #f44336 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #f6695e !important;
}

.link-light {
  color: #7c818b !important;
}
.link-light:hover, .link-light:focus {
  color: #969aa2 !important;
}

.link-dark {
  color: #1e293b !important;
}
.link-dark:hover, .link-dark:focus {
  color: #18212f !important;
}

.link-facebook {
  color: #3b5998 !important;
}
.link-facebook:hover, .link-facebook:focus {
  color: #2f477a !important;
}

.link-twitter {
  color: #00acee !important;
}
.link-twitter:hover, .link-twitter:focus {
  color: #33bdf1 !important;
}

.link-instagram {
  color: #E1306C !important;
}
.link-instagram:hover, .link-instagram:focus {
  color: #e75989 !important;
}

.link-white {
  color: #fff !important;
}
.link-white:hover, .link-white:focus {
  color: white !important;
}

.link-pink {
  color: #e91e63 !important;
}
.link-pink:hover, .link-pink:focus {
  color: #ed4b82 !important;
}

.link-teal {
  color: #009688 !important;
}
.link-teal:hover, .link-teal:focus {
  color: #33aba0 !important;
}

.link-orange {
  color: #ff5722 !important;
}
.link-orange:hover, .link-orange:focus {
  color: #ff794e !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.align-sub {
  vertical-align: sub !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-0-hover:hover {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: 5% !important;
}

.opacity-5-hover:hover {
  opacity: 5% !important;
}

.opacity-10 {
  opacity: 10% !important;
}

.opacity-10-hover:hover {
  opacity: 10% !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-25-hover:hover {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-50-hover:hover {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-75-hover:hover {
  opacity: 0.75 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-90-hover:hover {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-100-hover:hover {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(29, 48, 61, 0.06) !important;
}

.shadow-hover:hover {
  box-shadow: 0 0.5rem 1rem rgba(29, 48, 61, 0.06) !important;
}

.shadow-focus:focus {
  box-shadow: 0 0.5rem 1rem rgba(29, 48, 61, 0.06) !important;
}

.shadow-active:active {
  box-shadow: 0 0.5rem 1rem rgba(29, 48, 61, 0.06) !important;
}

.shadow-sm {
  box-shadow: 0 4px 0.5rem rgba(29, 48, 61, 0.03) !important;
}

.shadow-sm-hover:hover {
  box-shadow: 0 4px 0.5rem rgba(29, 48, 61, 0.03) !important;
}

.shadow-sm-focus:focus {
  box-shadow: 0 4px 0.5rem rgba(29, 48, 61, 0.03) !important;
}

.shadow-sm-active:active {
  box-shadow: 0 4px 0.5rem rgba(29, 48, 61, 0.03) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125) !important;
}

.shadow-lg-hover:hover {
  box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125) !important;
}

.shadow-lg-focus:focus {
  box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125) !important;
}

.shadow-lg-active:active {
  box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-none-hover:hover {
  box-shadow: none !important;
}

.shadow-none-focus:focus {
  box-shadow: none !important;
}

.shadow-none-active:active {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-1 {
  top: 1% !important;
}

.top-2 {
  top: 2% !important;
}

.top-3 {
  top: 3% !important;
}

.top-4 {
  top: 4% !important;
}

.top-5 {
  top: 5% !important;
}

.top-7 {
  top: 7% !important;
}

.top-10 {
  top: 10% !important;
}

.top-20 {
  top: 20% !important;
}

.top-25 {
  top: 25% !important;
}

.top-30 {
  top: 30% !important;
}

.top-35 {
  top: 35% !important;
}

.top-40 {
  top: 40% !important;
}

.top-45 {
  top: 45% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-1 {
  bottom: 1% !important;
}

.bottom-2 {
  bottom: 2% !important;
}

.bottom-3 {
  bottom: 3% !important;
}

.bottom-4 {
  bottom: 4% !important;
}

.bottom-5 {
  bottom: 5% !important;
}

.bottom-7 {
  bottom: 7% !important;
}

.bottom-10 {
  bottom: 10% !important;
}

.bottom-20 {
  bottom: 20% !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-30 {
  bottom: 30% !important;
}

.bottom-35 {
  bottom: 35% !important;
}

.bottom-40 {
  bottom: 40% !important;
}

.bottom-45 {
  bottom: 45% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-1 {
  left: 1% !important;
}

.start-2 {
  left: 2% !important;
}

.start-3 {
  left: 3% !important;
}

.start-4 {
  left: 4% !important;
}

.start-5 {
  left: 5% !important;
}

.start-7 {
  left: 7% !important;
}

.start-10 {
  left: 10% !important;
}

.start-20 {
  left: 20% !important;
}

.start-25 {
  left: 25% !important;
}

.start-30 {
  left: 30% !important;
}

.start-35 {
  left: 35% !important;
}

.start-40 {
  left: 40% !important;
}

.start-45 {
  left: 45% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-1 {
  right: 1% !important;
}

.end-2 {
  right: 2% !important;
}

.end-3 {
  right: 3% !important;
}

.end-4 {
  right: 4% !important;
}

.end-5 {
  right: 5% !important;
}

.end-7 {
  right: 7% !important;
}

.end-10 {
  right: 10% !important;
}

.end-20 {
  right: 20% !important;
}

.end-25 {
  right: 25% !important;
}

.end-30 {
  right: 30% !important;
}

.end-35 {
  right: 35% !important;
}

.end-40 {
  right: 40% !important;
}

.end-45 {
  right: 45% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-hover:hover {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-focus:focus {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-active:active {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-0-hover:hover {
  border: 0 !important;
}

.border-0-focus:focus {
  border: 0 !important;
}

.border-0-active:active {
  border: 0 !important;
}

.border-transparent {
  border: 1px solid transparent !important;
}

.border-transparent-hover:hover {
  border: 1px solid transparent !important;
}

.border-transparent-focus:focus {
  border: 1px solid transparent !important;
}

.border-transparent-active:active {
  border: 1px solid transparent !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-success-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-success-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-success-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-info-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-info-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-info-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-light-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-light-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-light-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-dark-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-dark-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-dark-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-facebook {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-facebook-rgb), var(--bs-border-opacity)) !important;
}

.border-facebook-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-facebook-rgb), var(--bs-border-opacity)) !important;
}

.border-facebook-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-facebook-rgb), var(--bs-border-opacity)) !important;
}

.border-facebook-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-facebook-rgb), var(--bs-border-opacity)) !important;
}

.border-twitter {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-twitter-rgb), var(--bs-border-opacity)) !important;
}

.border-twitter-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-twitter-rgb), var(--bs-border-opacity)) !important;
}

.border-twitter-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-twitter-rgb), var(--bs-border-opacity)) !important;
}

.border-twitter-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-twitter-rgb), var(--bs-border-opacity)) !important;
}

.border-instagram {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-instagram-rgb), var(--bs-border-opacity)) !important;
}

.border-instagram-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-instagram-rgb), var(--bs-border-opacity)) !important;
}

.border-instagram-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-instagram-rgb), var(--bs-border-opacity)) !important;
}

.border-instagram-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-instagram-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-white-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-white-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-white-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-teal {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-rgb), var(--bs-border-opacity)) !important;
}

.border-orange {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-hover:hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-focus:focus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-active:active {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important;
}

.border-transparent {
  --bs-border-opacity: 1;
  border-color: transparent !important;
}

.border-transparent-hover:hover {
  --bs-border-opacity: 1;
  border-color: transparent !important;
}

.border-transparent-focus:focus {
  --bs-border-opacity: 1;
  border-color: transparent !important;
}

.border-transparent-active:active {
  --bs-border-opacity: 1;
  border-color: transparent !important;
}

.border-0 {
  --bs-border-width: 0;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-10 {
  width: 10% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-80 {
  width: 80% !important;
}

.w-110 {
  width: 110% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.vw-10 {
  width: 10vw !important;
}

.vw-25 {
  width: 25vw !important;
}

.vw-50 {
  width: 50vw !important;
}

.vw-75 {
  width: 75vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vw-10 {
  min-width: 10vw !important;
}

.min-vw-25 {
  min-width: 25vw !important;
}

.min-vw-50 {
  min-width: 50vw !important;
}

.min-vw-75 {
  min-width: 75vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.m-h-1 {
  max-height: 0.25rem !important;
}

.m-h-2 {
  max-height: 0.5rem !important;
}

.m-h-3 {
  max-height: 0.75rem !important;
}

.m-h-4 {
  max-height: 1rem !important;
}

.m-h-5 {
  max-height: 1.25rem !important;
}

.m-h-6 {
  max-height: 1.5rem !important;
}

.m-h-7 {
  max-height: 1.75rem !important;
}

.m-h-8 {
  max-height: 2rem !important;
}

.m-h-9 {
  max-height: 2.25rem !important;
}

.m-h-10 {
  max-height: 2.5rem !important;
}

.m-h-12 {
  max-height: 3rem !important;
}

.m-h-14 {
  max-height: 3.5rem !important;
}

.m-h-16 {
  max-height: 4rem !important;
}

.m-h-20 {
  max-height: 5rem !important;
}

.m-h-24 {
  max-height: 6rem !important;
}

.m-h-28 {
  max-height: 7rem !important;
}

.m-h-30 {
  max-height: 7.25rem !important;
}

.m-h-32 {
  max-height: 8rem !important;
}

.m-h-36 {
  max-height: 9rem !important;
}

.m-h-40 {
  max-height: 10rem !important;
}

.m-h-44 {
  max-height: 11rem !important;
}

.m-h-48 {
  max-height: 12rem !important;
}

.m-h-52 {
  max-height: 15rem !important;
}

.m-h-56 {
  max-height: 18rem !important;
}

.m-h-60 {
  max-height: 21rem !important;
}

.m-h-64 {
  max-height: 24rem !important;
}

.m-h-68 {
  max-height: 27rem !important;
}

.m-h-72 {
  max-height: 30rem !important;
}

.m-h-76 {
  max-height: 33rem !important;
}

.m-h-80 {
  max-height: 36rem !important;
}

.m-h-84 {
  max-height: 40rem !important;
}

.m-h-88 {
  max-height: 44rem !important;
}

.m-h-92 {
  max-height: 50rem !important;
}

.m-h-96 {
  max-height: 56rem !important;
}

.m-h-100 {
  max-height: 60rem !important;
}

.m-h-0 {
  max-height: 0 !important;
}

.vh-100 {
  height: 100vh !important;
}

.vh-10 {
  height: 10vh !important;
}

.vh-25 {
  height: 25vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.min-vh-10 {
  min-height: 10vh !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-40 {
  min-height: 40vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-60 {
  min-height: 60vh !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 3.5rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 5rem !important;
}

.m-9 {
  margin: 6rem !important;
}

.m-10 {
  margin: 7rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-7 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-8 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-9 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-10 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-9 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-10 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 3.5rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 5rem !important;
}

.mt-9 {
  margin-top: 6rem !important;
}

.mt-10 {
  margin-top: 7rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 3.5rem !important;
}

.me-7 {
  margin-right: 4rem !important;
}

.me-8 {
  margin-right: 5rem !important;
}

.me-9 {
  margin-right: 6rem !important;
}

.me-10 {
  margin-right: 7rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 3.5rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.mb-9 {
  margin-bottom: 6rem !important;
}

.mb-10 {
  margin-bottom: 7rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 3.5rem !important;
}

.ms-7 {
  margin-left: 4rem !important;
}

.ms-8 {
  margin-left: 5rem !important;
}

.ms-9 {
  margin-left: 6rem !important;
}

.ms-10 {
  margin-left: 7rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -3.5rem !important;
}

.m-n7 {
  margin: -4rem !important;
}

.m-n8 {
  margin: -5rem !important;
}

.m-n9 {
  margin: -6rem !important;
}

.m-n10 {
  margin: -7rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n7 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n8 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n9 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n10 {
  margin-right: -7rem !important;
  margin-left: -7rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n7 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n8 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n9 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n10 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -3.5rem !important;
}

.mt-n7 {
  margin-top: -4rem !important;
}

.mt-n8 {
  margin-top: -5rem !important;
}

.mt-n9 {
  margin-top: -6rem !important;
}

.mt-n10 {
  margin-top: -7rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -3.5rem !important;
}

.me-n7 {
  margin-right: -4rem !important;
}

.me-n8 {
  margin-right: -5rem !important;
}

.me-n9 {
  margin-right: -6rem !important;
}

.me-n10 {
  margin-right: -7rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -3.5rem !important;
}

.mb-n7 {
  margin-bottom: -4rem !important;
}

.mb-n8 {
  margin-bottom: -5rem !important;
}

.mb-n9 {
  margin-bottom: -6rem !important;
}

.mb-n10 {
  margin-bottom: -7rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -3.5rem !important;
}

.ms-n7 {
  margin-left: -4rem !important;
}

.ms-n8 {
  margin-left: -5rem !important;
}

.ms-n9 {
  margin-left: -6rem !important;
}

.ms-n10 {
  margin-left: -7rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 3.5rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 5rem !important;
}

.p-9 {
  padding: 6rem !important;
}

.p-10 {
  padding: 7rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-7 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-8 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-9 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-10 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-9 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-10 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 3.5rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 5rem !important;
}

.pt-9 {
  padding-top: 6rem !important;
}

.pt-10 {
  padding-top: 7rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 3.5rem !important;
}

.pe-7 {
  padding-right: 4rem !important;
}

.pe-8 {
  padding-right: 5rem !important;
}

.pe-9 {
  padding-right: 6rem !important;
}

.pe-10 {
  padding-right: 7rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 3.5rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 5rem !important;
}

.pb-9 {
  padding-bottom: 6rem !important;
}

.pb-10 {
  padding-bottom: 7rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 3.5rem !important;
}

.ps-7 {
  padding-left: 4rem !important;
}

.ps-8 {
  padding-left: 5rem !important;
}

.ps-9 {
  padding-left: 6rem !important;
}

.ps-10 {
  padding-left: 7rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 3.5rem !important;
}

.gap-7 {
  gap: 4rem !important;
}

.gap-8 {
  gap: 5rem !important;
}

.gap-9 {
  gap: 6rem !important;
}

.gap-10 {
  gap: 7rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.36875rem + 1.425vw) !important;
}

.fs-2 {
  font-size: calc(1.32rem + 0.84vw) !important;
}

.fs-3 {
  font-size: calc(1.295625rem + 0.5475vw) !important;
}

.fs-4 {
  font-size: calc(1.27125rem + 0.255vw) !important;
}

.fs-5 {
  font-size: 1.21875rem !important;
}

.fs-6 {
  font-size: 0.975rem !important;
}

.fs-7 {
  font-size: 90% !important;
}

.fs-8 {
  font-size: 80% !important;
}

.fs-9 {
  font-size: 0.75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-success-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-success-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-success-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-info-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-info-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-info-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-light-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-light-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-light-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-facebook {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-facebook-rgb), var(--bs-text-opacity)) !important;
}

.text-facebook-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-facebook-rgb), var(--bs-text-opacity)) !important;
}

.text-facebook-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-facebook-rgb), var(--bs-text-opacity)) !important;
}

.text-facebook-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-facebook-rgb), var(--bs-text-opacity)) !important;
}

.text-twitter {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-twitter-rgb), var(--bs-text-opacity)) !important;
}

.text-twitter-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-twitter-rgb), var(--bs-text-opacity)) !important;
}

.text-twitter-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-twitter-rgb), var(--bs-text-opacity)) !important;
}

.text-twitter-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-twitter-rgb), var(--bs-text-opacity)) !important;
}

.text-instagram {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-instagram-rgb), var(--bs-text-opacity)) !important;
}

.text-instagram-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-instagram-rgb), var(--bs-text-opacity)) !important;
}

.text-instagram-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-instagram-rgb), var(--bs-text-opacity)) !important;
}

.text-instagram-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-instagram-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-white-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-white-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-white-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-teal {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-black-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-black-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-black-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-body-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-body-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-body-active:active {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #7c818b !important;
}

.text-muted-hover:hover {
  --bs-text-opacity: 1;
  color: #7c818b !important;
}

.text-muted-focus:focus {
  --bs-text-opacity: 1;
  color: #7c818b !important;
}

.text-muted-active:active {
  --bs-text-opacity: 1;
  color: #7c818b !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-50-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-50-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-50-active:active {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-50-hover:hover {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-50-focus:focus {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-50-active:active {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-reset-hover:hover {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-reset-focus:focus {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-reset-active:active {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-facebook {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
}

.bg-facebook-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
}

.bg-twitter {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
}

.bg-twitter-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
}

.bg-instagram {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
}

.bg-instagram-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-hover:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-transparent-hover:hover {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.f-w-1 {
  width: 0.25rem !important;
}

.f-w-2 {
  width: 0.5rem !important;
}

.f-w-3 {
  width: 0.75rem !important;
}

.f-w-4 {
  width: 1rem !important;
}

.f-w-5 {
  width: 1.25rem !important;
}

.f-w-6 {
  width: 1.5rem !important;
}

.f-w-7 {
  width: 1.75rem !important;
}

.f-w-8 {
  width: 2rem !important;
}

.f-w-9 {
  width: 2.25rem !important;
}

.f-w-10 {
  width: 2.5rem !important;
}

.f-w-12 {
  width: 3rem !important;
}

.f-w-14 {
  width: 3.5rem !important;
}

.f-w-16 {
  width: 4rem !important;
}

.f-w-20 {
  width: 5rem !important;
}

.f-w-24 {
  width: 6rem !important;
}

.f-w-28 {
  width: 7rem !important;
}

.f-w-30 {
  width: 7.25rem !important;
}

.f-w-32 {
  width: 8rem !important;
}

.f-w-36 {
  width: 9rem !important;
}

.f-w-40 {
  width: 10rem !important;
}

.f-w-44 {
  width: 11rem !important;
}

.f-w-48 {
  width: 12rem !important;
}

.f-w-52 {
  width: 15rem !important;
}

.f-w-56 {
  width: 18rem !important;
}

.f-w-60 {
  width: 21rem !important;
}

.f-w-64 {
  width: 24rem !important;
}

.f-w-68 {
  width: 27rem !important;
}

.f-w-72 {
  width: 30rem !important;
}

.f-w-76 {
  width: 33rem !important;
}

.f-w-80 {
  width: 36rem !important;
}

.f-w-84 {
  width: 40rem !important;
}

.f-w-88 {
  width: 44rem !important;
}

.f-w-92 {
  width: 50rem !important;
}

.f-w-96 {
  width: 56rem !important;
}

.f-w-100 {
  width: 60rem !important;
}

.f-w-0 {
  width: 0 !important;
}

.f-h-1 {
  height: 0.25rem !important;
}

.f-h-2 {
  height: 0.5rem !important;
}

.f-h-3 {
  height: 0.75rem !important;
}

.f-h-4 {
  height: 1rem !important;
}

.f-h-5 {
  height: 1.25rem !important;
}

.f-h-6 {
  height: 1.5rem !important;
}

.f-h-7 {
  height: 1.75rem !important;
}

.f-h-8 {
  height: 2rem !important;
}

.f-h-9 {
  height: 2.25rem !important;
}

.f-h-10 {
  height: 2.5rem !important;
}

.f-h-12 {
  height: 3rem !important;
}

.f-h-14 {
  height: 3.5rem !important;
}

.f-h-16 {
  height: 4rem !important;
}

.f-h-20 {
  height: 5rem !important;
}

.f-h-24 {
  height: 6rem !important;
}

.f-h-28 {
  height: 7rem !important;
}

.f-h-30 {
  height: 7.25rem !important;
}

.f-h-32 {
  height: 8rem !important;
}

.f-h-36 {
  height: 9rem !important;
}

.f-h-40 {
  height: 10rem !important;
}

.f-h-44 {
  height: 11rem !important;
}

.f-h-48 {
  height: 12rem !important;
}

.f-h-52 {
  height: 15rem !important;
}

.f-h-56 {
  height: 18rem !important;
}

.f-h-60 {
  height: 21rem !important;
}

.f-h-64 {
  height: 24rem !important;
}

.f-h-68 {
  height: 27rem !important;
}

.f-h-72 {
  height: 30rem !important;
}

.f-h-76 {
  height: 33rem !important;
}

.f-h-80 {
  height: 36rem !important;
}

.f-h-84 {
  height: 40rem !important;
}

.f-h-88 {
  height: 44rem !important;
}

.f-h-92 {
  height: 50rem !important;
}

.f-h-96 {
  height: 56rem !important;
}

.f-h-100 {
  height: 60rem !important;
}

.f-h-0 {
  height: 0 !important;
}

.transform-y-0 {
  transform: translateY(0) !important;
}

.transform-y-25 {
  transform: translateY(25%) !important;
}

.transform-y-50 {
  transform: translateY(50%) !important;
}

.transform-y-75 {
  transform: translateY(75%) !important;
}

.transform-y-100 {
  transform: translateY(100%) !important;
}

.transform-y--25 {
  transform: translateY(-25%) !important;
}

.transform-y--50 {
  transform: translateY(-50%) !important;
}

.transform-y--75 {
  transform: translateY(-75%) !important;
}

.transform-y--100 {
  transform: translateY(-100%) !important;
}

.tracking-tighter {
  letter-spacing: -0.05em !important;
}

.tracking-tight {
  letter-spacing: -0.025em !important;
}

.tracking-wide {
  letter-spacing: 0.065em !important;
}

.tracking-wider {
  letter-spacing: 0.25em !important;
}

.tracking-widest {
  letter-spacing: 0.4em !important;
}

.transition-all {
  transition: all linear 0.3s !important;
}

.z-index-n1 {
  z-index: -1 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-20 {
  z-index: 20 !important;
}

.z-index-30 {
  z-index: 30 !important;
}

.z-index-40 {
  z-index: 40 !important;
}

.z-index-50 {
  z-index: 50 !important;
}

.max-vh-10 {
  max-height: 10vh !important;
}

.max-vh-25 {
  max-height: 25vh !important;
}

.max-vh-50 {
  max-height: 50vh !important;
}

.max-vh-60 {
  max-height: 60vh !important;
}

.max-vh-75 {
  max-height: 75vh !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
  .border-sm {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-sm-hover:hover {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-sm-focus:focus {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-sm-active:active {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-sm-0-hover:hover {
    border: 0 !important;
  }
  .border-sm-0-focus:focus {
    border: 0 !important;
  }
  .border-sm-0-active:active {
    border: 0 !important;
  }
  .border-sm-transparent {
    border: 1px solid transparent !important;
  }
  .border-sm-transparent-hover:hover {
    border: 1px solid transparent !important;
  }
  .border-sm-transparent-focus:focus {
    border: 1px solid transparent !important;
  }
  .border-sm-transparent-active:active {
    border: 1px solid transparent !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-110 {
    width: 110% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .m-h-sm-1 {
    max-height: 0.25rem !important;
  }
  .m-h-sm-2 {
    max-height: 0.5rem !important;
  }
  .m-h-sm-3 {
    max-height: 0.75rem !important;
  }
  .m-h-sm-4 {
    max-height: 1rem !important;
  }
  .m-h-sm-5 {
    max-height: 1.25rem !important;
  }
  .m-h-sm-6 {
    max-height: 1.5rem !important;
  }
  .m-h-sm-7 {
    max-height: 1.75rem !important;
  }
  .m-h-sm-8 {
    max-height: 2rem !important;
  }
  .m-h-sm-9 {
    max-height: 2.25rem !important;
  }
  .m-h-sm-10 {
    max-height: 2.5rem !important;
  }
  .m-h-sm-12 {
    max-height: 3rem !important;
  }
  .m-h-sm-14 {
    max-height: 3.5rem !important;
  }
  .m-h-sm-16 {
    max-height: 4rem !important;
  }
  .m-h-sm-20 {
    max-height: 5rem !important;
  }
  .m-h-sm-24 {
    max-height: 6rem !important;
  }
  .m-h-sm-28 {
    max-height: 7rem !important;
  }
  .m-h-sm-30 {
    max-height: 7.25rem !important;
  }
  .m-h-sm-32 {
    max-height: 8rem !important;
  }
  .m-h-sm-36 {
    max-height: 9rem !important;
  }
  .m-h-sm-40 {
    max-height: 10rem !important;
  }
  .m-h-sm-44 {
    max-height: 11rem !important;
  }
  .m-h-sm-48 {
    max-height: 12rem !important;
  }
  .m-h-sm-52 {
    max-height: 15rem !important;
  }
  .m-h-sm-56 {
    max-height: 18rem !important;
  }
  .m-h-sm-60 {
    max-height: 21rem !important;
  }
  .m-h-sm-64 {
    max-height: 24rem !important;
  }
  .m-h-sm-68 {
    max-height: 27rem !important;
  }
  .m-h-sm-72 {
    max-height: 30rem !important;
  }
  .m-h-sm-76 {
    max-height: 33rem !important;
  }
  .m-h-sm-80 {
    max-height: 36rem !important;
  }
  .m-h-sm-84 {
    max-height: 40rem !important;
  }
  .m-h-sm-88 {
    max-height: 44rem !important;
  }
  .m-h-sm-92 {
    max-height: 50rem !important;
  }
  .m-h-sm-96 {
    max-height: 56rem !important;
  }
  .m-h-sm-100 {
    max-height: 60rem !important;
  }
  .m-h-sm-0 {
    max-height: 0 !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 3.5rem !important;
  }
  .m-sm-7 {
    margin: 4rem !important;
  }
  .m-sm-8 {
    margin: 5rem !important;
  }
  .m-sm-9 {
    margin: 6rem !important;
  }
  .m-sm-10 {
    margin: 7rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-9 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-10 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-9 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-10 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 4rem !important;
  }
  .mt-sm-8 {
    margin-top: 5rem !important;
  }
  .mt-sm-9 {
    margin-top: 6rem !important;
  }
  .mt-sm-10 {
    margin-top: 7rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 3.5rem !important;
  }
  .me-sm-7 {
    margin-right: 4rem !important;
  }
  .me-sm-8 {
    margin-right: 5rem !important;
  }
  .me-sm-9 {
    margin-right: 6rem !important;
  }
  .me-sm-10 {
    margin-right: 7rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 7rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 4rem !important;
  }
  .ms-sm-8 {
    margin-left: 5rem !important;
  }
  .ms-sm-9 {
    margin-left: 6rem !important;
  }
  .ms-sm-10 {
    margin-left: 7rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -3.5rem !important;
  }
  .m-sm-n7 {
    margin: -4rem !important;
  }
  .m-sm-n8 {
    margin: -5rem !important;
  }
  .m-sm-n9 {
    margin: -6rem !important;
  }
  .m-sm-n10 {
    margin: -7rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n10 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n9 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n10 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -4rem !important;
  }
  .mt-sm-n8 {
    margin-top: -5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -6rem !important;
  }
  .mt-sm-n10 {
    margin-top: -7rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n6 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -4rem !important;
  }
  .me-sm-n8 {
    margin-right: -5rem !important;
  }
  .me-sm-n9 {
    margin-right: -6rem !important;
  }
  .me-sm-n10 {
    margin-right: -7rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -7rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n6 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -4rem !important;
  }
  .ms-sm-n8 {
    margin-left: -5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -6rem !important;
  }
  .ms-sm-n10 {
    margin-left: -7rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 3.5rem !important;
  }
  .p-sm-7 {
    padding: 4rem !important;
  }
  .p-sm-8 {
    padding: 5rem !important;
  }
  .p-sm-9 {
    padding: 6rem !important;
  }
  .p-sm-10 {
    padding: 7rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-9 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-10 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-9 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-10 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 4rem !important;
  }
  .pt-sm-8 {
    padding-top: 5rem !important;
  }
  .pt-sm-9 {
    padding-top: 6rem !important;
  }
  .pt-sm-10 {
    padding-top: 7rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 4rem !important;
  }
  .pe-sm-8 {
    padding-right: 5rem !important;
  }
  .pe-sm-9 {
    padding-right: 6rem !important;
  }
  .pe-sm-10 {
    padding-right: 7rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 7rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 4rem !important;
  }
  .ps-sm-8 {
    padding-left: 5rem !important;
  }
  .ps-sm-9 {
    padding-left: 6rem !important;
  }
  .ps-sm-10 {
    padding-left: 7rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 3.5rem !important;
  }
  .gap-sm-7 {
    gap: 4rem !important;
  }
  .gap-sm-8 {
    gap: 5rem !important;
  }
  .gap-sm-9 {
    gap: 6rem !important;
  }
  .gap-sm-10 {
    gap: 7rem !important;
  }
  .fs-sm-1 {
    font-size: calc(1.36875rem + 1.425vw) !important;
  }
  .fs-sm-2 {
    font-size: calc(1.32rem + 0.84vw) !important;
  }
  .fs-sm-3 {
    font-size: calc(1.295625rem + 0.5475vw) !important;
  }
  .fs-sm-4 {
    font-size: calc(1.27125rem + 0.255vw) !important;
  }
  .fs-sm-5 {
    font-size: 1.21875rem !important;
  }
  .fs-sm-6 {
    font-size: 0.975rem !important;
  }
  .fs-sm-7 {
    font-size: 90% !important;
  }
  .fs-sm-8 {
    font-size: 80% !important;
  }
  .fs-sm-9 {
    font-size: 0.75rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .bg-sm-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-primary-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-secondary-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-success-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-info-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-warning-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-danger-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-light-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-dark-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-facebook {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-facebook-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-twitter {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-twitter-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-instagram {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-instagram-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-white-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-pink {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-pink-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-teal {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-teal-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-orange {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-orange-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-black-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-body-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .bg-sm-transparent-hover:hover {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .f-w-sm-1 {
    width: 0.25rem !important;
  }
  .f-w-sm-2 {
    width: 0.5rem !important;
  }
  .f-w-sm-3 {
    width: 0.75rem !important;
  }
  .f-w-sm-4 {
    width: 1rem !important;
  }
  .f-w-sm-5 {
    width: 1.25rem !important;
  }
  .f-w-sm-6 {
    width: 1.5rem !important;
  }
  .f-w-sm-7 {
    width: 1.75rem !important;
  }
  .f-w-sm-8 {
    width: 2rem !important;
  }
  .f-w-sm-9 {
    width: 2.25rem !important;
  }
  .f-w-sm-10 {
    width: 2.5rem !important;
  }
  .f-w-sm-12 {
    width: 3rem !important;
  }
  .f-w-sm-14 {
    width: 3.5rem !important;
  }
  .f-w-sm-16 {
    width: 4rem !important;
  }
  .f-w-sm-20 {
    width: 5rem !important;
  }
  .f-w-sm-24 {
    width: 6rem !important;
  }
  .f-w-sm-28 {
    width: 7rem !important;
  }
  .f-w-sm-30 {
    width: 7.25rem !important;
  }
  .f-w-sm-32 {
    width: 8rem !important;
  }
  .f-w-sm-36 {
    width: 9rem !important;
  }
  .f-w-sm-40 {
    width: 10rem !important;
  }
  .f-w-sm-44 {
    width: 11rem !important;
  }
  .f-w-sm-48 {
    width: 12rem !important;
  }
  .f-w-sm-52 {
    width: 15rem !important;
  }
  .f-w-sm-56 {
    width: 18rem !important;
  }
  .f-w-sm-60 {
    width: 21rem !important;
  }
  .f-w-sm-64 {
    width: 24rem !important;
  }
  .f-w-sm-68 {
    width: 27rem !important;
  }
  .f-w-sm-72 {
    width: 30rem !important;
  }
  .f-w-sm-76 {
    width: 33rem !important;
  }
  .f-w-sm-80 {
    width: 36rem !important;
  }
  .f-w-sm-84 {
    width: 40rem !important;
  }
  .f-w-sm-88 {
    width: 44rem !important;
  }
  .f-w-sm-92 {
    width: 50rem !important;
  }
  .f-w-sm-96 {
    width: 56rem !important;
  }
  .f-w-sm-100 {
    width: 60rem !important;
  }
  .f-w-sm-0 {
    width: 0 !important;
  }
  .f-h-sm-1 {
    height: 0.25rem !important;
  }
  .f-h-sm-2 {
    height: 0.5rem !important;
  }
  .f-h-sm-3 {
    height: 0.75rem !important;
  }
  .f-h-sm-4 {
    height: 1rem !important;
  }
  .f-h-sm-5 {
    height: 1.25rem !important;
  }
  .f-h-sm-6 {
    height: 1.5rem !important;
  }
  .f-h-sm-7 {
    height: 1.75rem !important;
  }
  .f-h-sm-8 {
    height: 2rem !important;
  }
  .f-h-sm-9 {
    height: 2.25rem !important;
  }
  .f-h-sm-10 {
    height: 2.5rem !important;
  }
  .f-h-sm-12 {
    height: 3rem !important;
  }
  .f-h-sm-14 {
    height: 3.5rem !important;
  }
  .f-h-sm-16 {
    height: 4rem !important;
  }
  .f-h-sm-20 {
    height: 5rem !important;
  }
  .f-h-sm-24 {
    height: 6rem !important;
  }
  .f-h-sm-28 {
    height: 7rem !important;
  }
  .f-h-sm-30 {
    height: 7.25rem !important;
  }
  .f-h-sm-32 {
    height: 8rem !important;
  }
  .f-h-sm-36 {
    height: 9rem !important;
  }
  .f-h-sm-40 {
    height: 10rem !important;
  }
  .f-h-sm-44 {
    height: 11rem !important;
  }
  .f-h-sm-48 {
    height: 12rem !important;
  }
  .f-h-sm-52 {
    height: 15rem !important;
  }
  .f-h-sm-56 {
    height: 18rem !important;
  }
  .f-h-sm-60 {
    height: 21rem !important;
  }
  .f-h-sm-64 {
    height: 24rem !important;
  }
  .f-h-sm-68 {
    height: 27rem !important;
  }
  .f-h-sm-72 {
    height: 30rem !important;
  }
  .f-h-sm-76 {
    height: 33rem !important;
  }
  .f-h-sm-80 {
    height: 36rem !important;
  }
  .f-h-sm-84 {
    height: 40rem !important;
  }
  .f-h-sm-88 {
    height: 44rem !important;
  }
  .f-h-sm-92 {
    height: 50rem !important;
  }
  .f-h-sm-96 {
    height: 56rem !important;
  }
  .f-h-sm-100 {
    height: 60rem !important;
  }
  .f-h-sm-0 {
    height: 0 !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
  .border-md {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-md-hover:hover {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-md-focus:focus {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-md-active:active {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-md-0-hover:hover {
    border: 0 !important;
  }
  .border-md-0-focus:focus {
    border: 0 !important;
  }
  .border-md-0-active:active {
    border: 0 !important;
  }
  .border-md-transparent {
    border: 1px solid transparent !important;
  }
  .border-md-transparent-hover:hover {
    border: 1px solid transparent !important;
  }
  .border-md-transparent-focus:focus {
    border: 1px solid transparent !important;
  }
  .border-md-transparent-active:active {
    border: 1px solid transparent !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-110 {
    width: 110% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .m-h-md-1 {
    max-height: 0.25rem !important;
  }
  .m-h-md-2 {
    max-height: 0.5rem !important;
  }
  .m-h-md-3 {
    max-height: 0.75rem !important;
  }
  .m-h-md-4 {
    max-height: 1rem !important;
  }
  .m-h-md-5 {
    max-height: 1.25rem !important;
  }
  .m-h-md-6 {
    max-height: 1.5rem !important;
  }
  .m-h-md-7 {
    max-height: 1.75rem !important;
  }
  .m-h-md-8 {
    max-height: 2rem !important;
  }
  .m-h-md-9 {
    max-height: 2.25rem !important;
  }
  .m-h-md-10 {
    max-height: 2.5rem !important;
  }
  .m-h-md-12 {
    max-height: 3rem !important;
  }
  .m-h-md-14 {
    max-height: 3.5rem !important;
  }
  .m-h-md-16 {
    max-height: 4rem !important;
  }
  .m-h-md-20 {
    max-height: 5rem !important;
  }
  .m-h-md-24 {
    max-height: 6rem !important;
  }
  .m-h-md-28 {
    max-height: 7rem !important;
  }
  .m-h-md-30 {
    max-height: 7.25rem !important;
  }
  .m-h-md-32 {
    max-height: 8rem !important;
  }
  .m-h-md-36 {
    max-height: 9rem !important;
  }
  .m-h-md-40 {
    max-height: 10rem !important;
  }
  .m-h-md-44 {
    max-height: 11rem !important;
  }
  .m-h-md-48 {
    max-height: 12rem !important;
  }
  .m-h-md-52 {
    max-height: 15rem !important;
  }
  .m-h-md-56 {
    max-height: 18rem !important;
  }
  .m-h-md-60 {
    max-height: 21rem !important;
  }
  .m-h-md-64 {
    max-height: 24rem !important;
  }
  .m-h-md-68 {
    max-height: 27rem !important;
  }
  .m-h-md-72 {
    max-height: 30rem !important;
  }
  .m-h-md-76 {
    max-height: 33rem !important;
  }
  .m-h-md-80 {
    max-height: 36rem !important;
  }
  .m-h-md-84 {
    max-height: 40rem !important;
  }
  .m-h-md-88 {
    max-height: 44rem !important;
  }
  .m-h-md-92 {
    max-height: 50rem !important;
  }
  .m-h-md-96 {
    max-height: 56rem !important;
  }
  .m-h-md-100 {
    max-height: 60rem !important;
  }
  .m-h-md-0 {
    max-height: 0 !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 3.5rem !important;
  }
  .m-md-7 {
    margin: 4rem !important;
  }
  .m-md-8 {
    margin: 5rem !important;
  }
  .m-md-9 {
    margin: 6rem !important;
  }
  .m-md-10 {
    margin: 7rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-9 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-10 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-9 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-10 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 3.5rem !important;
  }
  .mt-md-7 {
    margin-top: 4rem !important;
  }
  .mt-md-8 {
    margin-top: 5rem !important;
  }
  .mt-md-9 {
    margin-top: 6rem !important;
  }
  .mt-md-10 {
    margin-top: 7rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 3.5rem !important;
  }
  .me-md-7 {
    margin-right: 4rem !important;
  }
  .me-md-8 {
    margin-right: 5rem !important;
  }
  .me-md-9 {
    margin-right: 6rem !important;
  }
  .me-md-10 {
    margin-right: 7rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 4rem !important;
  }
  .mb-md-8 {
    margin-bottom: 5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 6rem !important;
  }
  .mb-md-10 {
    margin-bottom: 7rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 3.5rem !important;
  }
  .ms-md-7 {
    margin-left: 4rem !important;
  }
  .ms-md-8 {
    margin-left: 5rem !important;
  }
  .ms-md-9 {
    margin-left: 6rem !important;
  }
  .ms-md-10 {
    margin-left: 7rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -3.5rem !important;
  }
  .m-md-n7 {
    margin: -4rem !important;
  }
  .m-md-n8 {
    margin: -5rem !important;
  }
  .m-md-n9 {
    margin: -6rem !important;
  }
  .m-md-n10 {
    margin: -7rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n9 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n10 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-md-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n9 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n10 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -4rem !important;
  }
  .mt-md-n8 {
    margin-top: -5rem !important;
  }
  .mt-md-n9 {
    margin-top: -6rem !important;
  }
  .mt-md-n10 {
    margin-top: -7rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n6 {
    margin-right: -3.5rem !important;
  }
  .me-md-n7 {
    margin-right: -4rem !important;
  }
  .me-md-n8 {
    margin-right: -5rem !important;
  }
  .me-md-n9 {
    margin-right: -6rem !important;
  }
  .me-md-n10 {
    margin-right: -7rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -7rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n6 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -4rem !important;
  }
  .ms-md-n8 {
    margin-left: -5rem !important;
  }
  .ms-md-n9 {
    margin-left: -6rem !important;
  }
  .ms-md-n10 {
    margin-left: -7rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 3.5rem !important;
  }
  .p-md-7 {
    padding: 4rem !important;
  }
  .p-md-8 {
    padding: 5rem !important;
  }
  .p-md-9 {
    padding: 6rem !important;
  }
  .p-md-10 {
    padding: 7rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-9 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-10 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-9 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-10 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 3.5rem !important;
  }
  .pt-md-7 {
    padding-top: 4rem !important;
  }
  .pt-md-8 {
    padding-top: 5rem !important;
  }
  .pt-md-9 {
    padding-top: 6rem !important;
  }
  .pt-md-10 {
    padding-top: 7rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 3.5rem !important;
  }
  .pe-md-7 {
    padding-right: 4rem !important;
  }
  .pe-md-8 {
    padding-right: 5rem !important;
  }
  .pe-md-9 {
    padding-right: 6rem !important;
  }
  .pe-md-10 {
    padding-right: 7rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 4rem !important;
  }
  .pb-md-8 {
    padding-bottom: 5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 6rem !important;
  }
  .pb-md-10 {
    padding-bottom: 7rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 3.5rem !important;
  }
  .ps-md-7 {
    padding-left: 4rem !important;
  }
  .ps-md-8 {
    padding-left: 5rem !important;
  }
  .ps-md-9 {
    padding-left: 6rem !important;
  }
  .ps-md-10 {
    padding-left: 7rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 3.5rem !important;
  }
  .gap-md-7 {
    gap: 4rem !important;
  }
  .gap-md-8 {
    gap: 5rem !important;
  }
  .gap-md-9 {
    gap: 6rem !important;
  }
  .gap-md-10 {
    gap: 7rem !important;
  }
  .fs-md-1 {
    font-size: calc(1.36875rem + 1.425vw) !important;
  }
  .fs-md-2 {
    font-size: calc(1.32rem + 0.84vw) !important;
  }
  .fs-md-3 {
    font-size: calc(1.295625rem + 0.5475vw) !important;
  }
  .fs-md-4 {
    font-size: calc(1.27125rem + 0.255vw) !important;
  }
  .fs-md-5 {
    font-size: 1.21875rem !important;
  }
  .fs-md-6 {
    font-size: 0.975rem !important;
  }
  .fs-md-7 {
    font-size: 90% !important;
  }
  .fs-md-8 {
    font-size: 80% !important;
  }
  .fs-md-9 {
    font-size: 0.75rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .bg-md-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-primary-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-secondary-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-success-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-info-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-warning-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-danger-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-light-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-dark-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-facebook {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-facebook-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-twitter {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-twitter-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-instagram {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-instagram-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-white-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-pink {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-pink-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-teal {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-teal-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-orange {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-orange-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-black-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-body-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .bg-md-transparent-hover:hover {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .f-w-md-1 {
    width: 0.25rem !important;
  }
  .f-w-md-2 {
    width: 0.5rem !important;
  }
  .f-w-md-3 {
    width: 0.75rem !important;
  }
  .f-w-md-4 {
    width: 1rem !important;
  }
  .f-w-md-5 {
    width: 1.25rem !important;
  }
  .f-w-md-6 {
    width: 1.5rem !important;
  }
  .f-w-md-7 {
    width: 1.75rem !important;
  }
  .f-w-md-8 {
    width: 2rem !important;
  }
  .f-w-md-9 {
    width: 2.25rem !important;
  }
  .f-w-md-10 {
    width: 2.5rem !important;
  }
  .f-w-md-12 {
    width: 3rem !important;
  }
  .f-w-md-14 {
    width: 3.5rem !important;
  }
  .f-w-md-16 {
    width: 4rem !important;
  }
  .f-w-md-20 {
    width: 5rem !important;
  }
  .f-w-md-24 {
    width: 6rem !important;
  }
  .f-w-md-28 {
    width: 7rem !important;
  }
  .f-w-md-30 {
    width: 7.25rem !important;
  }
  .f-w-md-32 {
    width: 8rem !important;
  }
  .f-w-md-36 {
    width: 9rem !important;
  }
  .f-w-md-40 {
    width: 10rem !important;
  }
  .f-w-md-44 {
    width: 11rem !important;
  }
  .f-w-md-48 {
    width: 12rem !important;
  }
  .f-w-md-52 {
    width: 15rem !important;
  }
  .f-w-md-56 {
    width: 18rem !important;
  }
  .f-w-md-60 {
    width: 21rem !important;
  }
  .f-w-md-64 {
    width: 24rem !important;
  }
  .f-w-md-68 {
    width: 27rem !important;
  }
  .f-w-md-72 {
    width: 30rem !important;
  }
  .f-w-md-76 {
    width: 33rem !important;
  }
  .f-w-md-80 {
    width: 36rem !important;
  }
  .f-w-md-84 {
    width: 40rem !important;
  }
  .f-w-md-88 {
    width: 44rem !important;
  }
  .f-w-md-92 {
    width: 50rem !important;
  }
  .f-w-md-96 {
    width: 56rem !important;
  }
  .f-w-md-100 {
    width: 60rem !important;
  }
  .f-w-md-0 {
    width: 0 !important;
  }
  .f-h-md-1 {
    height: 0.25rem !important;
  }
  .f-h-md-2 {
    height: 0.5rem !important;
  }
  .f-h-md-3 {
    height: 0.75rem !important;
  }
  .f-h-md-4 {
    height: 1rem !important;
  }
  .f-h-md-5 {
    height: 1.25rem !important;
  }
  .f-h-md-6 {
    height: 1.5rem !important;
  }
  .f-h-md-7 {
    height: 1.75rem !important;
  }
  .f-h-md-8 {
    height: 2rem !important;
  }
  .f-h-md-9 {
    height: 2.25rem !important;
  }
  .f-h-md-10 {
    height: 2.5rem !important;
  }
  .f-h-md-12 {
    height: 3rem !important;
  }
  .f-h-md-14 {
    height: 3.5rem !important;
  }
  .f-h-md-16 {
    height: 4rem !important;
  }
  .f-h-md-20 {
    height: 5rem !important;
  }
  .f-h-md-24 {
    height: 6rem !important;
  }
  .f-h-md-28 {
    height: 7rem !important;
  }
  .f-h-md-30 {
    height: 7.25rem !important;
  }
  .f-h-md-32 {
    height: 8rem !important;
  }
  .f-h-md-36 {
    height: 9rem !important;
  }
  .f-h-md-40 {
    height: 10rem !important;
  }
  .f-h-md-44 {
    height: 11rem !important;
  }
  .f-h-md-48 {
    height: 12rem !important;
  }
  .f-h-md-52 {
    height: 15rem !important;
  }
  .f-h-md-56 {
    height: 18rem !important;
  }
  .f-h-md-60 {
    height: 21rem !important;
  }
  .f-h-md-64 {
    height: 24rem !important;
  }
  .f-h-md-68 {
    height: 27rem !important;
  }
  .f-h-md-72 {
    height: 30rem !important;
  }
  .f-h-md-76 {
    height: 33rem !important;
  }
  .f-h-md-80 {
    height: 36rem !important;
  }
  .f-h-md-84 {
    height: 40rem !important;
  }
  .f-h-md-88 {
    height: 44rem !important;
  }
  .f-h-md-92 {
    height: 50rem !important;
  }
  .f-h-md-96 {
    height: 56rem !important;
  }
  .f-h-md-100 {
    height: 60rem !important;
  }
  .f-h-md-0 {
    height: 0 !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .border-lg {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-lg-hover:hover {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-lg-focus:focus {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-lg-active:active {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-lg-0-hover:hover {
    border: 0 !important;
  }
  .border-lg-0-focus:focus {
    border: 0 !important;
  }
  .border-lg-0-active:active {
    border: 0 !important;
  }
  .border-lg-transparent {
    border: 1px solid transparent !important;
  }
  .border-lg-transparent-hover:hover {
    border: 1px solid transparent !important;
  }
  .border-lg-transparent-focus:focus {
    border: 1px solid transparent !important;
  }
  .border-lg-transparent-active:active {
    border: 1px solid transparent !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-110 {
    width: 110% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .m-h-lg-1 {
    max-height: 0.25rem !important;
  }
  .m-h-lg-2 {
    max-height: 0.5rem !important;
  }
  .m-h-lg-3 {
    max-height: 0.75rem !important;
  }
  .m-h-lg-4 {
    max-height: 1rem !important;
  }
  .m-h-lg-5 {
    max-height: 1.25rem !important;
  }
  .m-h-lg-6 {
    max-height: 1.5rem !important;
  }
  .m-h-lg-7 {
    max-height: 1.75rem !important;
  }
  .m-h-lg-8 {
    max-height: 2rem !important;
  }
  .m-h-lg-9 {
    max-height: 2.25rem !important;
  }
  .m-h-lg-10 {
    max-height: 2.5rem !important;
  }
  .m-h-lg-12 {
    max-height: 3rem !important;
  }
  .m-h-lg-14 {
    max-height: 3.5rem !important;
  }
  .m-h-lg-16 {
    max-height: 4rem !important;
  }
  .m-h-lg-20 {
    max-height: 5rem !important;
  }
  .m-h-lg-24 {
    max-height: 6rem !important;
  }
  .m-h-lg-28 {
    max-height: 7rem !important;
  }
  .m-h-lg-30 {
    max-height: 7.25rem !important;
  }
  .m-h-lg-32 {
    max-height: 8rem !important;
  }
  .m-h-lg-36 {
    max-height: 9rem !important;
  }
  .m-h-lg-40 {
    max-height: 10rem !important;
  }
  .m-h-lg-44 {
    max-height: 11rem !important;
  }
  .m-h-lg-48 {
    max-height: 12rem !important;
  }
  .m-h-lg-52 {
    max-height: 15rem !important;
  }
  .m-h-lg-56 {
    max-height: 18rem !important;
  }
  .m-h-lg-60 {
    max-height: 21rem !important;
  }
  .m-h-lg-64 {
    max-height: 24rem !important;
  }
  .m-h-lg-68 {
    max-height: 27rem !important;
  }
  .m-h-lg-72 {
    max-height: 30rem !important;
  }
  .m-h-lg-76 {
    max-height: 33rem !important;
  }
  .m-h-lg-80 {
    max-height: 36rem !important;
  }
  .m-h-lg-84 {
    max-height: 40rem !important;
  }
  .m-h-lg-88 {
    max-height: 44rem !important;
  }
  .m-h-lg-92 {
    max-height: 50rem !important;
  }
  .m-h-lg-96 {
    max-height: 56rem !important;
  }
  .m-h-lg-100 {
    max-height: 60rem !important;
  }
  .m-h-lg-0 {
    max-height: 0 !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 3.5rem !important;
  }
  .m-lg-7 {
    margin: 4rem !important;
  }
  .m-lg-8 {
    margin: 5rem !important;
  }
  .m-lg-9 {
    margin: 6rem !important;
  }
  .m-lg-10 {
    margin: 7rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-9 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-10 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-9 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-10 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 4rem !important;
  }
  .mt-lg-8 {
    margin-top: 5rem !important;
  }
  .mt-lg-9 {
    margin-top: 6rem !important;
  }
  .mt-lg-10 {
    margin-top: 7rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 3.5rem !important;
  }
  .me-lg-7 {
    margin-right: 4rem !important;
  }
  .me-lg-8 {
    margin-right: 5rem !important;
  }
  .me-lg-9 {
    margin-right: 6rem !important;
  }
  .me-lg-10 {
    margin-right: 7rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 4rem !important;
  }
  .ms-lg-8 {
    margin-left: 5rem !important;
  }
  .ms-lg-9 {
    margin-left: 6rem !important;
  }
  .ms-lg-10 {
    margin-left: 7rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -3.5rem !important;
  }
  .m-lg-n7 {
    margin: -4rem !important;
  }
  .m-lg-n8 {
    margin: -5rem !important;
  }
  .m-lg-n9 {
    margin: -6rem !important;
  }
  .m-lg-n10 {
    margin: -7rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n10 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n9 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n10 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -4rem !important;
  }
  .mt-lg-n8 {
    margin-top: -5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -6rem !important;
  }
  .mt-lg-n10 {
    margin-top: -7rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n6 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -4rem !important;
  }
  .me-lg-n8 {
    margin-right: -5rem !important;
  }
  .me-lg-n9 {
    margin-right: -6rem !important;
  }
  .me-lg-n10 {
    margin-right: -7rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -7rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n6 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -4rem !important;
  }
  .ms-lg-n8 {
    margin-left: -5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -6rem !important;
  }
  .ms-lg-n10 {
    margin-left: -7rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 3.5rem !important;
  }
  .p-lg-7 {
    padding: 4rem !important;
  }
  .p-lg-8 {
    padding: 5rem !important;
  }
  .p-lg-9 {
    padding: 6rem !important;
  }
  .p-lg-10 {
    padding: 7rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-9 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-10 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-9 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-10 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 4rem !important;
  }
  .pt-lg-8 {
    padding-top: 5rem !important;
  }
  .pt-lg-9 {
    padding-top: 6rem !important;
  }
  .pt-lg-10 {
    padding-top: 7rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 4rem !important;
  }
  .pe-lg-8 {
    padding-right: 5rem !important;
  }
  .pe-lg-9 {
    padding-right: 6rem !important;
  }
  .pe-lg-10 {
    padding-right: 7rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 7rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 4rem !important;
  }
  .ps-lg-8 {
    padding-left: 5rem !important;
  }
  .ps-lg-9 {
    padding-left: 6rem !important;
  }
  .ps-lg-10 {
    padding-left: 7rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 3.5rem !important;
  }
  .gap-lg-7 {
    gap: 4rem !important;
  }
  .gap-lg-8 {
    gap: 5rem !important;
  }
  .gap-lg-9 {
    gap: 6rem !important;
  }
  .gap-lg-10 {
    gap: 7rem !important;
  }
  .fs-lg-1 {
    font-size: calc(1.36875rem + 1.425vw) !important;
  }
  .fs-lg-2 {
    font-size: calc(1.32rem + 0.84vw) !important;
  }
  .fs-lg-3 {
    font-size: calc(1.295625rem + 0.5475vw) !important;
  }
  .fs-lg-4 {
    font-size: calc(1.27125rem + 0.255vw) !important;
  }
  .fs-lg-5 {
    font-size: 1.21875rem !important;
  }
  .fs-lg-6 {
    font-size: 0.975rem !important;
  }
  .fs-lg-7 {
    font-size: 90% !important;
  }
  .fs-lg-8 {
    font-size: 80% !important;
  }
  .fs-lg-9 {
    font-size: 0.75rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .bg-lg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-primary-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-secondary-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-success-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-info-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-warning-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-danger-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-light-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-dark-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-facebook {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-facebook-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-twitter {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-twitter-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-instagram {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-instagram-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-white-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-pink {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-pink-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-teal {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-teal-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-orange {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-orange-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-black-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-body-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .bg-lg-transparent-hover:hover {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .f-w-lg-1 {
    width: 0.25rem !important;
  }
  .f-w-lg-2 {
    width: 0.5rem !important;
  }
  .f-w-lg-3 {
    width: 0.75rem !important;
  }
  .f-w-lg-4 {
    width: 1rem !important;
  }
  .f-w-lg-5 {
    width: 1.25rem !important;
  }
  .f-w-lg-6 {
    width: 1.5rem !important;
  }
  .f-w-lg-7 {
    width: 1.75rem !important;
  }
  .f-w-lg-8 {
    width: 2rem !important;
  }
  .f-w-lg-9 {
    width: 2.25rem !important;
  }
  .f-w-lg-10 {
    width: 2.5rem !important;
  }
  .f-w-lg-12 {
    width: 3rem !important;
  }
  .f-w-lg-14 {
    width: 3.5rem !important;
  }
  .f-w-lg-16 {
    width: 4rem !important;
  }
  .f-w-lg-20 {
    width: 5rem !important;
  }
  .f-w-lg-24 {
    width: 6rem !important;
  }
  .f-w-lg-28 {
    width: 7rem !important;
  }
  .f-w-lg-30 {
    width: 7.25rem !important;
  }
  .f-w-lg-32 {
    width: 8rem !important;
  }
  .f-w-lg-36 {
    width: 9rem !important;
  }
  .f-w-lg-40 {
    width: 10rem !important;
  }
  .f-w-lg-44 {
    width: 11rem !important;
  }
  .f-w-lg-48 {
    width: 12rem !important;
  }
  .f-w-lg-52 {
    width: 15rem !important;
  }
  .f-w-lg-56 {
    width: 18rem !important;
  }
  .f-w-lg-60 {
    width: 21rem !important;
  }
  .f-w-lg-64 {
    width: 24rem !important;
  }
  .f-w-lg-68 {
    width: 27rem !important;
  }
  .f-w-lg-72 {
    width: 30rem !important;
  }
  .f-w-lg-76 {
    width: 33rem !important;
  }
  .f-w-lg-80 {
    width: 36rem !important;
  }
  .f-w-lg-84 {
    width: 40rem !important;
  }
  .f-w-lg-88 {
    width: 44rem !important;
  }
  .f-w-lg-92 {
    width: 50rem !important;
  }
  .f-w-lg-96 {
    width: 56rem !important;
  }
  .f-w-lg-100 {
    width: 60rem !important;
  }
  .f-w-lg-0 {
    width: 0 !important;
  }
  .f-h-lg-1 {
    height: 0.25rem !important;
  }
  .f-h-lg-2 {
    height: 0.5rem !important;
  }
  .f-h-lg-3 {
    height: 0.75rem !important;
  }
  .f-h-lg-4 {
    height: 1rem !important;
  }
  .f-h-lg-5 {
    height: 1.25rem !important;
  }
  .f-h-lg-6 {
    height: 1.5rem !important;
  }
  .f-h-lg-7 {
    height: 1.75rem !important;
  }
  .f-h-lg-8 {
    height: 2rem !important;
  }
  .f-h-lg-9 {
    height: 2.25rem !important;
  }
  .f-h-lg-10 {
    height: 2.5rem !important;
  }
  .f-h-lg-12 {
    height: 3rem !important;
  }
  .f-h-lg-14 {
    height: 3.5rem !important;
  }
  .f-h-lg-16 {
    height: 4rem !important;
  }
  .f-h-lg-20 {
    height: 5rem !important;
  }
  .f-h-lg-24 {
    height: 6rem !important;
  }
  .f-h-lg-28 {
    height: 7rem !important;
  }
  .f-h-lg-30 {
    height: 7.25rem !important;
  }
  .f-h-lg-32 {
    height: 8rem !important;
  }
  .f-h-lg-36 {
    height: 9rem !important;
  }
  .f-h-lg-40 {
    height: 10rem !important;
  }
  .f-h-lg-44 {
    height: 11rem !important;
  }
  .f-h-lg-48 {
    height: 12rem !important;
  }
  .f-h-lg-52 {
    height: 15rem !important;
  }
  .f-h-lg-56 {
    height: 18rem !important;
  }
  .f-h-lg-60 {
    height: 21rem !important;
  }
  .f-h-lg-64 {
    height: 24rem !important;
  }
  .f-h-lg-68 {
    height: 27rem !important;
  }
  .f-h-lg-72 {
    height: 30rem !important;
  }
  .f-h-lg-76 {
    height: 33rem !important;
  }
  .f-h-lg-80 {
    height: 36rem !important;
  }
  .f-h-lg-84 {
    height: 40rem !important;
  }
  .f-h-lg-88 {
    height: 44rem !important;
  }
  .f-h-lg-92 {
    height: 50rem !important;
  }
  .f-h-lg-96 {
    height: 56rem !important;
  }
  .f-h-lg-100 {
    height: 60rem !important;
  }
  .f-h-lg-0 {
    height: 0 !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .border-xl {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xl-hover:hover {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xl-focus:focus {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xl-active:active {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-xl-0-hover:hover {
    border: 0 !important;
  }
  .border-xl-0-focus:focus {
    border: 0 !important;
  }
  .border-xl-0-active:active {
    border: 0 !important;
  }
  .border-xl-transparent {
    border: 1px solid transparent !important;
  }
  .border-xl-transparent-hover:hover {
    border: 1px solid transparent !important;
  }
  .border-xl-transparent-focus:focus {
    border: 1px solid transparent !important;
  }
  .border-xl-transparent-active:active {
    border: 1px solid transparent !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-110 {
    width: 110% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .m-h-xl-1 {
    max-height: 0.25rem !important;
  }
  .m-h-xl-2 {
    max-height: 0.5rem !important;
  }
  .m-h-xl-3 {
    max-height: 0.75rem !important;
  }
  .m-h-xl-4 {
    max-height: 1rem !important;
  }
  .m-h-xl-5 {
    max-height: 1.25rem !important;
  }
  .m-h-xl-6 {
    max-height: 1.5rem !important;
  }
  .m-h-xl-7 {
    max-height: 1.75rem !important;
  }
  .m-h-xl-8 {
    max-height: 2rem !important;
  }
  .m-h-xl-9 {
    max-height: 2.25rem !important;
  }
  .m-h-xl-10 {
    max-height: 2.5rem !important;
  }
  .m-h-xl-12 {
    max-height: 3rem !important;
  }
  .m-h-xl-14 {
    max-height: 3.5rem !important;
  }
  .m-h-xl-16 {
    max-height: 4rem !important;
  }
  .m-h-xl-20 {
    max-height: 5rem !important;
  }
  .m-h-xl-24 {
    max-height: 6rem !important;
  }
  .m-h-xl-28 {
    max-height: 7rem !important;
  }
  .m-h-xl-30 {
    max-height: 7.25rem !important;
  }
  .m-h-xl-32 {
    max-height: 8rem !important;
  }
  .m-h-xl-36 {
    max-height: 9rem !important;
  }
  .m-h-xl-40 {
    max-height: 10rem !important;
  }
  .m-h-xl-44 {
    max-height: 11rem !important;
  }
  .m-h-xl-48 {
    max-height: 12rem !important;
  }
  .m-h-xl-52 {
    max-height: 15rem !important;
  }
  .m-h-xl-56 {
    max-height: 18rem !important;
  }
  .m-h-xl-60 {
    max-height: 21rem !important;
  }
  .m-h-xl-64 {
    max-height: 24rem !important;
  }
  .m-h-xl-68 {
    max-height: 27rem !important;
  }
  .m-h-xl-72 {
    max-height: 30rem !important;
  }
  .m-h-xl-76 {
    max-height: 33rem !important;
  }
  .m-h-xl-80 {
    max-height: 36rem !important;
  }
  .m-h-xl-84 {
    max-height: 40rem !important;
  }
  .m-h-xl-88 {
    max-height: 44rem !important;
  }
  .m-h-xl-92 {
    max-height: 50rem !important;
  }
  .m-h-xl-96 {
    max-height: 56rem !important;
  }
  .m-h-xl-100 {
    max-height: 60rem !important;
  }
  .m-h-xl-0 {
    max-height: 0 !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 3.5rem !important;
  }
  .m-xl-7 {
    margin: 4rem !important;
  }
  .m-xl-8 {
    margin: 5rem !important;
  }
  .m-xl-9 {
    margin: 6rem !important;
  }
  .m-xl-10 {
    margin: 7rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-9 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-10 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-9 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-10 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 4rem !important;
  }
  .mt-xl-8 {
    margin-top: 5rem !important;
  }
  .mt-xl-9 {
    margin-top: 6rem !important;
  }
  .mt-xl-10 {
    margin-top: 7rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 3.5rem !important;
  }
  .me-xl-7 {
    margin-right: 4rem !important;
  }
  .me-xl-8 {
    margin-right: 5rem !important;
  }
  .me-xl-9 {
    margin-right: 6rem !important;
  }
  .me-xl-10 {
    margin-right: 7rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 4rem !important;
  }
  .ms-xl-8 {
    margin-left: 5rem !important;
  }
  .ms-xl-9 {
    margin-left: 6rem !important;
  }
  .ms-xl-10 {
    margin-left: 7rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -3.5rem !important;
  }
  .m-xl-n7 {
    margin: -4rem !important;
  }
  .m-xl-n8 {
    margin: -5rem !important;
  }
  .m-xl-n9 {
    margin: -6rem !important;
  }
  .m-xl-n10 {
    margin: -7rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n10 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n9 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n10 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -4rem !important;
  }
  .mt-xl-n8 {
    margin-top: -5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -6rem !important;
  }
  .mt-xl-n10 {
    margin-top: -7rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n6 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -4rem !important;
  }
  .me-xl-n8 {
    margin-right: -5rem !important;
  }
  .me-xl-n9 {
    margin-right: -6rem !important;
  }
  .me-xl-n10 {
    margin-right: -7rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -7rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n6 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -4rem !important;
  }
  .ms-xl-n8 {
    margin-left: -5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -6rem !important;
  }
  .ms-xl-n10 {
    margin-left: -7rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 3.5rem !important;
  }
  .p-xl-7 {
    padding: 4rem !important;
  }
  .p-xl-8 {
    padding: 5rem !important;
  }
  .p-xl-9 {
    padding: 6rem !important;
  }
  .p-xl-10 {
    padding: 7rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-9 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-10 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-9 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-10 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 4rem !important;
  }
  .pt-xl-8 {
    padding-top: 5rem !important;
  }
  .pt-xl-9 {
    padding-top: 6rem !important;
  }
  .pt-xl-10 {
    padding-top: 7rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 4rem !important;
  }
  .pe-xl-8 {
    padding-right: 5rem !important;
  }
  .pe-xl-9 {
    padding-right: 6rem !important;
  }
  .pe-xl-10 {
    padding-right: 7rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 7rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 4rem !important;
  }
  .ps-xl-8 {
    padding-left: 5rem !important;
  }
  .ps-xl-9 {
    padding-left: 6rem !important;
  }
  .ps-xl-10 {
    padding-left: 7rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 3.5rem !important;
  }
  .gap-xl-7 {
    gap: 4rem !important;
  }
  .gap-xl-8 {
    gap: 5rem !important;
  }
  .gap-xl-9 {
    gap: 6rem !important;
  }
  .gap-xl-10 {
    gap: 7rem !important;
  }
  .fs-xl-1 {
    font-size: calc(1.36875rem + 1.425vw) !important;
  }
  .fs-xl-2 {
    font-size: calc(1.32rem + 0.84vw) !important;
  }
  .fs-xl-3 {
    font-size: calc(1.295625rem + 0.5475vw) !important;
  }
  .fs-xl-4 {
    font-size: calc(1.27125rem + 0.255vw) !important;
  }
  .fs-xl-5 {
    font-size: 1.21875rem !important;
  }
  .fs-xl-6 {
    font-size: 0.975rem !important;
  }
  .fs-xl-7 {
    font-size: 90% !important;
  }
  .fs-xl-8 {
    font-size: 80% !important;
  }
  .fs-xl-9 {
    font-size: 0.75rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .bg-xl-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-primary-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-secondary-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-success-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-info-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-warning-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-danger-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-light-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-dark-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-facebook {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-facebook-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-twitter {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-twitter-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-instagram {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-instagram-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-white-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-pink {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-pink-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-teal {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-teal-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-orange {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-orange-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-black-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-body-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .bg-xl-transparent-hover:hover {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .f-w-xl-1 {
    width: 0.25rem !important;
  }
  .f-w-xl-2 {
    width: 0.5rem !important;
  }
  .f-w-xl-3 {
    width: 0.75rem !important;
  }
  .f-w-xl-4 {
    width: 1rem !important;
  }
  .f-w-xl-5 {
    width: 1.25rem !important;
  }
  .f-w-xl-6 {
    width: 1.5rem !important;
  }
  .f-w-xl-7 {
    width: 1.75rem !important;
  }
  .f-w-xl-8 {
    width: 2rem !important;
  }
  .f-w-xl-9 {
    width: 2.25rem !important;
  }
  .f-w-xl-10 {
    width: 2.5rem !important;
  }
  .f-w-xl-12 {
    width: 3rem !important;
  }
  .f-w-xl-14 {
    width: 3.5rem !important;
  }
  .f-w-xl-16 {
    width: 4rem !important;
  }
  .f-w-xl-20 {
    width: 5rem !important;
  }
  .f-w-xl-24 {
    width: 6rem !important;
  }
  .f-w-xl-28 {
    width: 7rem !important;
  }
  .f-w-xl-30 {
    width: 7.25rem !important;
  }
  .f-w-xl-32 {
    width: 8rem !important;
  }
  .f-w-xl-36 {
    width: 9rem !important;
  }
  .f-w-xl-40 {
    width: 10rem !important;
  }
  .f-w-xl-44 {
    width: 11rem !important;
  }
  .f-w-xl-48 {
    width: 12rem !important;
  }
  .f-w-xl-52 {
    width: 15rem !important;
  }
  .f-w-xl-56 {
    width: 18rem !important;
  }
  .f-w-xl-60 {
    width: 21rem !important;
  }
  .f-w-xl-64 {
    width: 24rem !important;
  }
  .f-w-xl-68 {
    width: 27rem !important;
  }
  .f-w-xl-72 {
    width: 30rem !important;
  }
  .f-w-xl-76 {
    width: 33rem !important;
  }
  .f-w-xl-80 {
    width: 36rem !important;
  }
  .f-w-xl-84 {
    width: 40rem !important;
  }
  .f-w-xl-88 {
    width: 44rem !important;
  }
  .f-w-xl-92 {
    width: 50rem !important;
  }
  .f-w-xl-96 {
    width: 56rem !important;
  }
  .f-w-xl-100 {
    width: 60rem !important;
  }
  .f-w-xl-0 {
    width: 0 !important;
  }
  .f-h-xl-1 {
    height: 0.25rem !important;
  }
  .f-h-xl-2 {
    height: 0.5rem !important;
  }
  .f-h-xl-3 {
    height: 0.75rem !important;
  }
  .f-h-xl-4 {
    height: 1rem !important;
  }
  .f-h-xl-5 {
    height: 1.25rem !important;
  }
  .f-h-xl-6 {
    height: 1.5rem !important;
  }
  .f-h-xl-7 {
    height: 1.75rem !important;
  }
  .f-h-xl-8 {
    height: 2rem !important;
  }
  .f-h-xl-9 {
    height: 2.25rem !important;
  }
  .f-h-xl-10 {
    height: 2.5rem !important;
  }
  .f-h-xl-12 {
    height: 3rem !important;
  }
  .f-h-xl-14 {
    height: 3.5rem !important;
  }
  .f-h-xl-16 {
    height: 4rem !important;
  }
  .f-h-xl-20 {
    height: 5rem !important;
  }
  .f-h-xl-24 {
    height: 6rem !important;
  }
  .f-h-xl-28 {
    height: 7rem !important;
  }
  .f-h-xl-30 {
    height: 7.25rem !important;
  }
  .f-h-xl-32 {
    height: 8rem !important;
  }
  .f-h-xl-36 {
    height: 9rem !important;
  }
  .f-h-xl-40 {
    height: 10rem !important;
  }
  .f-h-xl-44 {
    height: 11rem !important;
  }
  .f-h-xl-48 {
    height: 12rem !important;
  }
  .f-h-xl-52 {
    height: 15rem !important;
  }
  .f-h-xl-56 {
    height: 18rem !important;
  }
  .f-h-xl-60 {
    height: 21rem !important;
  }
  .f-h-xl-64 {
    height: 24rem !important;
  }
  .f-h-xl-68 {
    height: 27rem !important;
  }
  .f-h-xl-72 {
    height: 30rem !important;
  }
  .f-h-xl-76 {
    height: 33rem !important;
  }
  .f-h-xl-80 {
    height: 36rem !important;
  }
  .f-h-xl-84 {
    height: 40rem !important;
  }
  .f-h-xl-88 {
    height: 44rem !important;
  }
  .f-h-xl-92 {
    height: 50rem !important;
  }
  .f-h-xl-96 {
    height: 56rem !important;
  }
  .f-h-xl-100 {
    height: 60rem !important;
  }
  .f-h-xl-0 {
    height: 0 !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: sticky !important;
  }
  .border-xxl {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xxl-hover:hover {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xxl-focus:focus {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xxl-active:active {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-xxl-0-hover:hover {
    border: 0 !important;
  }
  .border-xxl-0-focus:focus {
    border: 0 !important;
  }
  .border-xxl-0-active:active {
    border: 0 !important;
  }
  .border-xxl-transparent {
    border: 1px solid transparent !important;
  }
  .border-xxl-transparent-hover:hover {
    border: 1px solid transparent !important;
  }
  .border-xxl-transparent-focus:focus {
    border: 1px solid transparent !important;
  }
  .border-xxl-transparent-active:active {
    border: 1px solid transparent !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-65 {
    width: 65% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-110 {
    width: 110% !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .m-h-xxl-1 {
    max-height: 0.25rem !important;
  }
  .m-h-xxl-2 {
    max-height: 0.5rem !important;
  }
  .m-h-xxl-3 {
    max-height: 0.75rem !important;
  }
  .m-h-xxl-4 {
    max-height: 1rem !important;
  }
  .m-h-xxl-5 {
    max-height: 1.25rem !important;
  }
  .m-h-xxl-6 {
    max-height: 1.5rem !important;
  }
  .m-h-xxl-7 {
    max-height: 1.75rem !important;
  }
  .m-h-xxl-8 {
    max-height: 2rem !important;
  }
  .m-h-xxl-9 {
    max-height: 2.25rem !important;
  }
  .m-h-xxl-10 {
    max-height: 2.5rem !important;
  }
  .m-h-xxl-12 {
    max-height: 3rem !important;
  }
  .m-h-xxl-14 {
    max-height: 3.5rem !important;
  }
  .m-h-xxl-16 {
    max-height: 4rem !important;
  }
  .m-h-xxl-20 {
    max-height: 5rem !important;
  }
  .m-h-xxl-24 {
    max-height: 6rem !important;
  }
  .m-h-xxl-28 {
    max-height: 7rem !important;
  }
  .m-h-xxl-30 {
    max-height: 7.25rem !important;
  }
  .m-h-xxl-32 {
    max-height: 8rem !important;
  }
  .m-h-xxl-36 {
    max-height: 9rem !important;
  }
  .m-h-xxl-40 {
    max-height: 10rem !important;
  }
  .m-h-xxl-44 {
    max-height: 11rem !important;
  }
  .m-h-xxl-48 {
    max-height: 12rem !important;
  }
  .m-h-xxl-52 {
    max-height: 15rem !important;
  }
  .m-h-xxl-56 {
    max-height: 18rem !important;
  }
  .m-h-xxl-60 {
    max-height: 21rem !important;
  }
  .m-h-xxl-64 {
    max-height: 24rem !important;
  }
  .m-h-xxl-68 {
    max-height: 27rem !important;
  }
  .m-h-xxl-72 {
    max-height: 30rem !important;
  }
  .m-h-xxl-76 {
    max-height: 33rem !important;
  }
  .m-h-xxl-80 {
    max-height: 36rem !important;
  }
  .m-h-xxl-84 {
    max-height: 40rem !important;
  }
  .m-h-xxl-88 {
    max-height: 44rem !important;
  }
  .m-h-xxl-92 {
    max-height: 50rem !important;
  }
  .m-h-xxl-96 {
    max-height: 56rem !important;
  }
  .m-h-xxl-100 {
    max-height: 60rem !important;
  }
  .m-h-xxl-0 {
    max-height: 0 !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 3.5rem !important;
  }
  .m-xxl-7 {
    margin: 4rem !important;
  }
  .m-xxl-8 {
    margin: 5rem !important;
  }
  .m-xxl-9 {
    margin: 6rem !important;
  }
  .m-xxl-10 {
    margin: 7rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-10 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-9 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-10 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 4rem !important;
  }
  .mt-xxl-8 {
    margin-top: 5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 6rem !important;
  }
  .mt-xxl-10 {
    margin-top: 7rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 4rem !important;
  }
  .me-xxl-8 {
    margin-right: 5rem !important;
  }
  .me-xxl-9 {
    margin-right: 6rem !important;
  }
  .me-xxl-10 {
    margin-right: 7rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 4rem !important;
  }
  .ms-xxl-8 {
    margin-left: 5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 6rem !important;
  }
  .ms-xxl-10 {
    margin-left: 7rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -3.5rem !important;
  }
  .m-xxl-n7 {
    margin: -4rem !important;
  }
  .m-xxl-n8 {
    margin: -5rem !important;
  }
  .m-xxl-n9 {
    margin: -6rem !important;
  }
  .m-xxl-n10 {
    margin: -7rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n10 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -7rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n6 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -4rem !important;
  }
  .me-xxl-n8 {
    margin-right: -5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -6rem !important;
  }
  .me-xxl-n10 {
    margin-right: -7rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -7rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -7rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 3.5rem !important;
  }
  .p-xxl-7 {
    padding: 4rem !important;
  }
  .p-xxl-8 {
    padding: 5rem !important;
  }
  .p-xxl-9 {
    padding: 6rem !important;
  }
  .p-xxl-10 {
    padding: 7rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-9 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-10 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-9 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-10 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 4rem !important;
  }
  .pt-xxl-8 {
    padding-top: 5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 6rem !important;
  }
  .pt-xxl-10 {
    padding-top: 7rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 4rem !important;
  }
  .pe-xxl-8 {
    padding-right: 5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 6rem !important;
  }
  .pe-xxl-10 {
    padding-right: 7rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 7rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 4rem !important;
  }
  .ps-xxl-8 {
    padding-left: 5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 6rem !important;
  }
  .ps-xxl-10 {
    padding-left: 7rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 3.5rem !important;
  }
  .gap-xxl-7 {
    gap: 4rem !important;
  }
  .gap-xxl-8 {
    gap: 5rem !important;
  }
  .gap-xxl-9 {
    gap: 6rem !important;
  }
  .gap-xxl-10 {
    gap: 7rem !important;
  }
  .fs-xxl-1 {
    font-size: calc(1.36875rem + 1.425vw) !important;
  }
  .fs-xxl-2 {
    font-size: calc(1.32rem + 0.84vw) !important;
  }
  .fs-xxl-3 {
    font-size: calc(1.295625rem + 0.5475vw) !important;
  }
  .fs-xxl-4 {
    font-size: calc(1.27125rem + 0.255vw) !important;
  }
  .fs-xxl-5 {
    font-size: 1.21875rem !important;
  }
  .fs-xxl-6 {
    font-size: 0.975rem !important;
  }
  .fs-xxl-7 {
    font-size: 90% !important;
  }
  .fs-xxl-8 {
    font-size: 80% !important;
  }
  .fs-xxl-9 {
    font-size: 0.75rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .bg-xxl-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-primary-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-secondary-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-success-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-info-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-warning-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-danger-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-light-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-dark-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-facebook {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-facebook-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-twitter {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-twitter-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-instagram {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-instagram-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-white-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-pink {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-pink-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-teal {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-teal-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-orange {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-orange-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-black-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-body-hover:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .bg-xxl-transparent-hover:hover {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .f-w-xxl-1 {
    width: 0.25rem !important;
  }
  .f-w-xxl-2 {
    width: 0.5rem !important;
  }
  .f-w-xxl-3 {
    width: 0.75rem !important;
  }
  .f-w-xxl-4 {
    width: 1rem !important;
  }
  .f-w-xxl-5 {
    width: 1.25rem !important;
  }
  .f-w-xxl-6 {
    width: 1.5rem !important;
  }
  .f-w-xxl-7 {
    width: 1.75rem !important;
  }
  .f-w-xxl-8 {
    width: 2rem !important;
  }
  .f-w-xxl-9 {
    width: 2.25rem !important;
  }
  .f-w-xxl-10 {
    width: 2.5rem !important;
  }
  .f-w-xxl-12 {
    width: 3rem !important;
  }
  .f-w-xxl-14 {
    width: 3.5rem !important;
  }
  .f-w-xxl-16 {
    width: 4rem !important;
  }
  .f-w-xxl-20 {
    width: 5rem !important;
  }
  .f-w-xxl-24 {
    width: 6rem !important;
  }
  .f-w-xxl-28 {
    width: 7rem !important;
  }
  .f-w-xxl-30 {
    width: 7.25rem !important;
  }
  .f-w-xxl-32 {
    width: 8rem !important;
  }
  .f-w-xxl-36 {
    width: 9rem !important;
  }
  .f-w-xxl-40 {
    width: 10rem !important;
  }
  .f-w-xxl-44 {
    width: 11rem !important;
  }
  .f-w-xxl-48 {
    width: 12rem !important;
  }
  .f-w-xxl-52 {
    width: 15rem !important;
  }
  .f-w-xxl-56 {
    width: 18rem !important;
  }
  .f-w-xxl-60 {
    width: 21rem !important;
  }
  .f-w-xxl-64 {
    width: 24rem !important;
  }
  .f-w-xxl-68 {
    width: 27rem !important;
  }
  .f-w-xxl-72 {
    width: 30rem !important;
  }
  .f-w-xxl-76 {
    width: 33rem !important;
  }
  .f-w-xxl-80 {
    width: 36rem !important;
  }
  .f-w-xxl-84 {
    width: 40rem !important;
  }
  .f-w-xxl-88 {
    width: 44rem !important;
  }
  .f-w-xxl-92 {
    width: 50rem !important;
  }
  .f-w-xxl-96 {
    width: 56rem !important;
  }
  .f-w-xxl-100 {
    width: 60rem !important;
  }
  .f-w-xxl-0 {
    width: 0 !important;
  }
  .f-h-xxl-1 {
    height: 0.25rem !important;
  }
  .f-h-xxl-2 {
    height: 0.5rem !important;
  }
  .f-h-xxl-3 {
    height: 0.75rem !important;
  }
  .f-h-xxl-4 {
    height: 1rem !important;
  }
  .f-h-xxl-5 {
    height: 1.25rem !important;
  }
  .f-h-xxl-6 {
    height: 1.5rem !important;
  }
  .f-h-xxl-7 {
    height: 1.75rem !important;
  }
  .f-h-xxl-8 {
    height: 2rem !important;
  }
  .f-h-xxl-9 {
    height: 2.25rem !important;
  }
  .f-h-xxl-10 {
    height: 2.5rem !important;
  }
  .f-h-xxl-12 {
    height: 3rem !important;
  }
  .f-h-xxl-14 {
    height: 3.5rem !important;
  }
  .f-h-xxl-16 {
    height: 4rem !important;
  }
  .f-h-xxl-20 {
    height: 5rem !important;
  }
  .f-h-xxl-24 {
    height: 6rem !important;
  }
  .f-h-xxl-28 {
    height: 7rem !important;
  }
  .f-h-xxl-30 {
    height: 7.25rem !important;
  }
  .f-h-xxl-32 {
    height: 8rem !important;
  }
  .f-h-xxl-36 {
    height: 9rem !important;
  }
  .f-h-xxl-40 {
    height: 10rem !important;
  }
  .f-h-xxl-44 {
    height: 11rem !important;
  }
  .f-h-xxl-48 {
    height: 12rem !important;
  }
  .f-h-xxl-52 {
    height: 15rem !important;
  }
  .f-h-xxl-56 {
    height: 18rem !important;
  }
  .f-h-xxl-60 {
    height: 21rem !important;
  }
  .f-h-xxl-64 {
    height: 24rem !important;
  }
  .f-h-xxl-68 {
    height: 27rem !important;
  }
  .f-h-xxl-72 {
    height: 30rem !important;
  }
  .f-h-xxl-76 {
    height: 33rem !important;
  }
  .f-h-xxl-80 {
    height: 36rem !important;
  }
  .f-h-xxl-84 {
    height: 40rem !important;
  }
  .f-h-xxl-88 {
    height: 44rem !important;
  }
  .f-h-xxl-92 {
    height: 50rem !important;
  }
  .f-h-xxl-96 {
    height: 56rem !important;
  }
  .f-h-xxl-100 {
    height: 60rem !important;
  }
  .f-h-xxl-0 {
    height: 0 !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.4375rem !important;
  }
  .fs-2 {
    font-size: 1.95rem !important;
  }
  .fs-3 {
    font-size: 1.70625rem !important;
  }
  .fs-4 {
    font-size: 1.4625rem !important;
  }
  .fs-sm-1 {
    font-size: 2.4375rem !important;
  }
  .fs-sm-2 {
    font-size: 1.95rem !important;
  }
  .fs-sm-3 {
    font-size: 1.70625rem !important;
  }
  .fs-sm-4 {
    font-size: 1.4625rem !important;
  }
  .fs-md-1 {
    font-size: 2.4375rem !important;
  }
  .fs-md-2 {
    font-size: 1.95rem !important;
  }
  .fs-md-3 {
    font-size: 1.70625rem !important;
  }
  .fs-md-4 {
    font-size: 1.4625rem !important;
  }
  .fs-lg-1 {
    font-size: 2.4375rem !important;
  }
  .fs-lg-2 {
    font-size: 1.95rem !important;
  }
  .fs-lg-3 {
    font-size: 1.70625rem !important;
  }
  .fs-lg-4 {
    font-size: 1.4625rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* Framework Custom Properties (CSS Variables)
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  /* Bootstrap vars */
  --bs-font-sans-serif: "Overpass", sans-serif;
  --bs-spacer: 1rem;
  --bs-gutter-width: 1.5rem;
  /* Theme vars */
  --theme-font-headings: "Overpass", sans-serif;
  --theme-sidebar-width: 250px;
  --theme-sidebar-closed-width: 60px;
  --theme-sidebar-transition: all ease-in .1s;
  --theme-sidebar-padding-x: 1.5rem;
  --theme-sidebar-padding-y: 1.5rem;
  --theme-navbar-height: 66px;
  --theme-page-content-padding-x: 1.5rem;
  --theme-bg-dark-opacity: rgba(0,0,0,.1);
  /* Icon font family */
  --theme-font-icon: "remixicon";
  --theme-font-icon-style: "normal";
  /* Custom checkbox styles */
  --theme-form-checkbox-before-size: 20px;
  --theme-form-checkbox-bg-before-size: 28px;
  --theme-form-checkbox-after-width: 6px;
  --theme-form-checkbox-after-height: 12px;
  --theme-form-checkbox-border-radius: 2px;
  --theme-form-checkbox-after-position-left: 7px;
  --theme-form-checkbox-after-position-top: 4px;
  --theme-form-checkbox-bg-after-position-left: 11px;
  --theme-form-checkbox-bg-after-position-top: 7px;
  --theme-form-checkbox-after-border-width: 2px;
  --theme-form-checkbox-active-color: #1e293b;
  --theme-form-radio-button-after-size: 10px;
  --theme-form-radio-button-after-position-left: 5px;
  --theme-form-radio-button-after-position-top: 6px;
  --theme-form-radio-button-border-radius: 100%;
  /* theme breakpoints using Bootstrap sass map */
  --theme-breakpoint-xs: 0;
  --theme-breakpoint-sm: 576px;
  --theme-breakpoint-md: 768px;
  --theme-breakpoint-lg: 992px;
  --theme-breakpoint-xl: 1200px;
  --theme-breakpoint-xxl: 1400px;
  /* theme max widths using sass map */
  --theme-maxwidth-breakpoint-sm: 100%;
  --theme-maxwidth-breakpoint-md: 100%;
  --theme-maxwidth-breakpoint-lg: 100%;
  --theme-maxwidth-breakpoint-xl: 1140px;
  --theme-maxwidth-breakpoint-xxl: 1320px;
}

/* Framework Base
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* generic site wide styles*/
body {
  overflow-x: hidden;
  min-height: 100vh;
}

*:focus,
*:active {
  outline: none !important;
  box-shadow: none !important;
}

#main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: calc(var(--theme-navbar-height) + 1.5rem);
  width: calc(100% - var(--theme-sidebar-width));
  margin-left: var(--theme-sidebar-width);
  overflow-x: hidden;
}

#main > .container-fluid, #main > .container-sm, #main > .container-md, #main > .container-lg, #main > .container-xl, #main > .container-xxl {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
}

.disable-child-pointer * {
  pointer-events: none;
}

/* handle menu hide/show for smaller screens */
.menu-hidden #main {
  width: 100%;
  margin-left: 0;
}

/* handle aside transitions */
.has-transition #main {
  transition: all ease-in 0.2s;
}

/* Framework Components
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Accordion */
.accordion-body {
  background: transparent;
}

/* Activity */
.list-group-activity {
  position: relative;
  border: 0;
}
.list-group-activity:before {
  border-left: 1px solid rgba(255, 255, 255, 0.08);
  content: "";
  height: 100%;
  left: 1.15rem;
  position: absolute;
  top: 1.25rem;
  bottom: 0;
}
.list-group-activity:last-child:before {
  display: none;
}

/* Alert */
/* Faded Alert */
.alert-primary-faded {
  background-color: rgba(30, 136, 229, 0.5);
  color: #1e88e5;
}

.alert-secondary-faded {
  background-color: rgba(98, 104, 116, 0.5);
  color: #626874;
}

.alert-success-faded {
  background-color: rgba(76, 175, 80, 0.5);
  color: #4caf50;
}

.alert-info-faded {
  background-color: rgba(0, 188, 212, 0.5);
  color: #00bcd4;
}

.alert-warning-faded {
  background-color: rgba(255, 193, 7, 0.5);
  color: #ffc107;
}

.alert-danger-faded {
  background-color: rgba(244, 67, 54, 0.5);
  color: #f44336;
}

.alert-light-faded {
  background-color: rgba(124, 129, 139, 0.5);
  color: #7c818b;
}

.alert-dark-faded {
  background-color: rgba(30, 41, 59, 0.5);
  color: #1e293b;
}

.alert-facebook-faded {
  background-color: rgba(59, 89, 152, 0.5);
  color: #3b5998;
}

.alert-twitter-faded {
  background-color: rgba(0, 172, 238, 0.5);
  color: #00acee;
}

.alert-instagram-faded {
  background-color: rgba(225, 48, 108, 0.5);
  color: #E1306C;
}

.alert-white-faded {
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
}

.alert-pink-faded {
  background-color: rgba(233, 30, 99, 0.5);
  color: #e91e63;
}

.alert-teal-faded {
  background-color: rgba(0, 150, 136, 0.5);
  color: #009688;
}

.alert-orange-faded {
  background-color: rgba(255, 87, 34, 0.5);
  color: #ff5722;
}

.alert-primary {
  background-color: rgba(30, 136, 229, 0.15);
  border-color: #1e88e5;
  color: white;
}

.alert-secondary {
  background-color: rgba(98, 104, 116, 0.15);
  border-color: #626874;
  color: white;
}

.alert-success {
  background-color: rgba(76, 175, 80, 0.15);
  border-color: #4caf50;
  color: white;
}

.alert-info {
  background-color: rgba(0, 188, 212, 0.15);
  border-color: #00bcd4;
  color: white;
}

.alert-warning {
  background-color: rgba(255, 193, 7, 0.15);
  border-color: #ffc107;
  color: white;
}

.alert-danger {
  background-color: rgba(244, 67, 54, 0.15);
  border-color: #f44336;
  color: white;
}

.alert-light {
  background-color: rgba(124, 129, 139, 0.15);
  border-color: #7c818b;
  color: white;
}

.alert-dark {
  background-color: rgba(30, 41, 59, 0.15);
  border-color: #1e293b;
  color: white;
}

.alert-facebook {
  background-color: rgba(59, 89, 152, 0.15);
  border-color: #3b5998;
  color: white;
}

.alert-twitter {
  background-color: rgba(0, 172, 238, 0.15);
  border-color: #00acee;
  color: white;
}

.alert-instagram {
  background-color: rgba(225, 48, 108, 0.15);
  border-color: #E1306C;
  color: white;
}

.alert-white {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: #fff;
  color: white;
}

.alert-pink {
  background-color: rgba(233, 30, 99, 0.15);
  border-color: #e91e63;
  color: white;
}

.alert-teal {
  background-color: rgba(0, 150, 136, 0.15);
  border-color: #009688;
  color: white;
}

.alert-orange {
  background-color: rgba(255, 87, 34, 0.15);
  border-color: #ff5722;
  color: white;
}

/* Aside */
.aside {
  width: var(--theme-sidebar-width);
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 900;
  left: 0;
  height: 100%;
  padding: 0 0 var(--theme-sidebar-padding-y) 0;
}
.aside a[data-bs-toggle=collapse]:after {
  content: "\ea56";
  margin-left: auto;
  margin-right: 0;
  font-family: var(--theme-font-icon);
  font-style: var(--theme-font-icon-style);
  font-size: 1.25rem;
}
.aside a[data-bs-toggle=collapse].collapsed:after {
  content: "\ea50";
}
.aside a[data-bs-toggle=collapse]:not(.collapsed) {
  color: #fff;
}
.aside .submenu {
  list-style: none;
  margin: 0;
  padding: 1rem 0 1rem;
}
.aside .submenu li {
  margin-bottom: 0.5rem;
}
.aside .submenu li:last-child {
  margin-bottom: 0;
}
.aside .submenu-link {
  background: transparent;
  font-size: 0.85rem;
  font-weight: 500;
  display: block;
  padding: 7px 1rem 7px 2rem;
  border-radius: 0.25rem;
  color: rgba(255, 255, 255, 0.75);
  transition: background ease-in 0.2s;
}
.aside .submenu-link.active, .aside .submenu-link:hover {
  background: rgba(255, 255, 255, 0.1);
}

.aside-menu {
  padding: 0 1.5rem;
}

.menu-item {
  position: relative;
  margin-bottom: 1rem;
}
.menu-item:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 34px;
  background: #1e88e5;
  opacity: 0;
}
.menu-item.active:before {
  opacity: 1;
}

.menu-link {
  color: rgba(255, 255, 255, 0.75);
}
.menu-link.active {
  color: #fff;
}

.menu-section {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: #1e88e5;
  letter-spacing: 0.025rem;
  margin-bottom: 0.5rem;
  height: 1rem;
  position: relative;
}

/* handle menu hide/show for smaller screens */
.menu-hidden .aside {
  transform: translateX(-100%);
}

/* handle menu overlay for mobile */
.menu-overlay-bg {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1999;
  opacity: 0;
  visibility: hidden;
}

.menu-overlay.menu-hidden .aside {
  z-index: 2000;
  transform: translateX(0);
}
.menu-overlay.menu-hidden .menu-overlay-bg {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1199.98px) {
  .aside {
    margin-left: 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    padding-top: 0;
    border-color: transparent;
  }
}
/* handle aside transitions */
.has-transition .aside {
  transition: all ease-in 0.2s;
}
.has-transition .menu-overlay-bg {
  transition: all ease-in 0.2s;
  z-index: 1999;
}

/* Avatar */
.avatar {
  position: relative;
}
.avatar img {
  border-radius: 100%;
  width: 6rem;
}

.avatar-sm img {
  width: 3rem;
}

.avatar-xs img {
  width: 2.25rem;
}

.avatar-profile {
  border-radius: 100%;
  display: block;
  border: 2px solid #1e88e5;
}

.avatar-profile-img {
  border: 3px solid #141c2f;
  position: relative;
  z-index: 1;
}

.avatar-dot {
  position: absolute;
  bottom: 5%;
  right: 8%;
  border: 4px solid #141c2f;
  z-index: 3;
}

.avatar-dot.dot-sm {
  border-width: 2px;
  right: 1%;
}

.avatar-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.avatar-group-img {
  margin-left: -0.9rem;
}
.avatar-group-img img {
  border: 3px solid #141c2f;
}

/* Breadcrumbs */
.breadcrumb-item a {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

/* Buttons */
/* faded button */
.btn-primary-faded {
  background-color: rgba(30, 136, 229, 0.5);
  color: #1e88e5;
}
.btn-primary-faded:hover, .btn-primary-faded:focus {
  background-color: rgba(30, 136, 229, 0.55);
  color: #1e88e5;
}
.btn-primary-faded.active {
  background-color: rgba(30, 136, 229, 0.55);
  color: #1e88e5;
}

.btn-secondary-faded {
  background-color: rgba(98, 104, 116, 0.5);
  color: #626874;
}
.btn-secondary-faded:hover, .btn-secondary-faded:focus {
  background-color: rgba(98, 104, 116, 0.55);
  color: #626874;
}
.btn-secondary-faded.active {
  background-color: rgba(98, 104, 116, 0.55);
  color: #626874;
}

.btn-success-faded {
  background-color: rgba(76, 175, 80, 0.5);
  color: #4caf50;
}
.btn-success-faded:hover, .btn-success-faded:focus {
  background-color: rgba(76, 175, 80, 0.55);
  color: #4caf50;
}
.btn-success-faded.active {
  background-color: rgba(76, 175, 80, 0.55);
  color: #4caf50;
}

.btn-info-faded {
  background-color: rgba(0, 188, 212, 0.5);
  color: #00bcd4;
}
.btn-info-faded:hover, .btn-info-faded:focus {
  background-color: rgba(0, 188, 212, 0.55);
  color: #00bcd4;
}
.btn-info-faded.active {
  background-color: rgba(0, 188, 212, 0.55);
  color: #00bcd4;
}

.btn-warning-faded {
  background-color: rgba(255, 193, 7, 0.5);
  color: #ffc107;
}
.btn-warning-faded:hover, .btn-warning-faded:focus {
  background-color: rgba(255, 193, 7, 0.55);
  color: #ffc107;
}
.btn-warning-faded.active {
  background-color: rgba(255, 193, 7, 0.55);
  color: #ffc107;
}

.btn-danger-faded {
  background-color: rgba(244, 67, 54, 0.5);
  color: #f44336;
}
.btn-danger-faded:hover, .btn-danger-faded:focus {
  background-color: rgba(244, 67, 54, 0.55);
  color: #f44336;
}
.btn-danger-faded.active {
  background-color: rgba(244, 67, 54, 0.55);
  color: #f44336;
}

.btn-light-faded {
  background-color: rgba(124, 129, 139, 0.5);
  color: #7c818b;
}
.btn-light-faded:hover, .btn-light-faded:focus {
  background-color: rgba(124, 129, 139, 0.55);
  color: #7c818b;
}
.btn-light-faded.active {
  background-color: rgba(124, 129, 139, 0.55);
  color: #7c818b;
}

.btn-dark-faded {
  background-color: rgba(30, 41, 59, 0.5);
  color: #1e293b;
}
.btn-dark-faded:hover, .btn-dark-faded:focus {
  background-color: rgba(30, 41, 59, 0.55);
  color: #1e293b;
}
.btn-dark-faded.active {
  background-color: rgba(30, 41, 59, 0.55);
  color: #1e293b;
}

.btn-facebook-faded {
  background-color: rgba(59, 89, 152, 0.5);
  color: #3b5998;
}
.btn-facebook-faded:hover, .btn-facebook-faded:focus {
  background-color: rgba(59, 89, 152, 0.55);
  color: #3b5998;
}
.btn-facebook-faded.active {
  background-color: rgba(59, 89, 152, 0.55);
  color: #3b5998;
}

.btn-twitter-faded {
  background-color: rgba(0, 172, 238, 0.5);
  color: #00acee;
}
.btn-twitter-faded:hover, .btn-twitter-faded:focus {
  background-color: rgba(0, 172, 238, 0.55);
  color: #00acee;
}
.btn-twitter-faded.active {
  background-color: rgba(0, 172, 238, 0.55);
  color: #00acee;
}

.btn-instagram-faded {
  background-color: rgba(225, 48, 108, 0.5);
  color: #E1306C;
}
.btn-instagram-faded:hover, .btn-instagram-faded:focus {
  background-color: rgba(225, 48, 108, 0.55);
  color: #E1306C;
}
.btn-instagram-faded.active {
  background-color: rgba(225, 48, 108, 0.55);
  color: #E1306C;
}

.btn-white-faded {
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
}
.btn-white-faded:hover, .btn-white-faded:focus {
  background-color: rgba(255, 255, 255, 0.55);
  color: #fff;
}
.btn-white-faded.active {
  background-color: rgba(255, 255, 255, 0.55);
  color: #fff;
}

.btn-pink-faded {
  background-color: rgba(233, 30, 99, 0.5);
  color: #e91e63;
}
.btn-pink-faded:hover, .btn-pink-faded:focus {
  background-color: rgba(233, 30, 99, 0.55);
  color: #e91e63;
}
.btn-pink-faded.active {
  background-color: rgba(233, 30, 99, 0.55);
  color: #e91e63;
}

.btn-teal-faded {
  background-color: rgba(0, 150, 136, 0.5);
  color: #009688;
}
.btn-teal-faded:hover, .btn-teal-faded:focus {
  background-color: rgba(0, 150, 136, 0.55);
  color: #009688;
}
.btn-teal-faded.active {
  background-color: rgba(0, 150, 136, 0.55);
  color: #009688;
}

.btn-orange-faded {
  background-color: rgba(255, 87, 34, 0.5);
  color: #ff5722;
}
.btn-orange-faded:hover, .btn-orange-faded:focus {
  background-color: rgba(255, 87, 34, 0.55);
  color: #ff5722;
}
.btn-orange-faded.active {
  background-color: rgba(255, 87, 34, 0.55);
  color: #ff5722;
}

.btn-icon {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
}

.btn-hover-dark {
  transition: background ease-in-out 0.2s;
}
.btn-hover-dark:hover {
  background: rgba(0, 0, 0, 0.3);
}

/* Cards */
.card-title {
  font-weight: 600;
}

.card-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

/* Charts */
.chart {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}
.chart canvas {
  width: 100% !important;
}

.chart-sm {
  height: 3rem;
}

.chart-md {
  height: 10rem;
}

.chart-lg {
  height: 20rem;
}

/* Dropdown */
.dropdown-menu {
  box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125);
}
.dropdown-menu li {
  margin-bottom: 0.5rem;
}
.dropdown-menu li:last-child {
  margin-bottom: 0;
}

.dropdown-toggle-icon {
  text-decoration: none;
}
.dropdown-toggle-icon:after {
  display: none !important;
}

.dropdown-divider {
  opacity: 1;
  margin: 1rem -0.75rem;
}

.dropdown-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  margin: 0 -0.75rem;
}

.dropdown-lg {
  min-width: 17rem;
}

.dropdown > .btn.dropdown-toggle-icon {
  color: #7c818b;
}

/* Footer */
.footer {
  margin-top: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  margin-left: calc(-1 * var(--bs-gutter-width) * 2);
  margin-right: calc(-1 * var(--bs-gutter-width) * 2);
  display: flex;
  padding: 1.25rem calc(var(--bs-gutter-width) * 2);
  justify-content: space-between;
  align-items: center;
}

/* Forms */
.form-group {
  margin-bottom: 1.5rem;
}

.form {
  width: 500px;
  max-width: 100%;
}

.form-label-light {
  color: #7c818b;
}

.form-control-light {
  border-color: #cbcdd1;
}

/* Goal */
.goal .form-check-input:checked + .form-check-label {
  text-decoration: line-through;
  opacity: 0.25;
}

/* Navbar */
.navbar-brand {
  width: calc(var(--theme-sidebar-width) - var(--bs-gutter-width));
  height: var(--theme-navbar-height);
  padding-right: var(--bs-gutter-width);
}

.navbar {
  height: var(--theme-navbar-height);
  margin-left: var(--theme-sidebar-width);
}

.navbar-actions {
  padding-right: var(--theme-page-content-padding-x);
}

/* menu toggle icons */
.unfold {
  display: none;
}

/* handle menu close/open */
.menu-collapsed .navbar-brand {
  width: calc(var(--theme-sidebar-closed-width) - var(--bs-gutter-width));
}
.menu-collapsed .navbar-brand span {
  visibility: hidden;
}
.menu-collapsed .fold {
  display: none;
}
.menu-collapsed .unfold {
  display: block;
}

/* handle menu hide/show for smaller screens */
.menu-hidden .navbar {
  width: auto;
  margin-left: 0;
}
.menu-hidden .fold {
  display: none;
}
.menu-hidden .unfold {
  display: block;
}

/* handle aside transitions */
.has-transition .navbar {
  transition: all ease-in 0.2s;
}

/* Offcanvas */
.offcanvas-header {
  align-items: center;
  padding-bottom: 0;
}

/* Pagination */
.page-link {
  border-radius: 0.25rem;
  margin: 0 2px;
}

/* Search */
.navbar-search {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #141c2f;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  z-index: 500;
  transition: opacity ease-in 0.2s, transform ease-in-out 0.2s;
  transform: translateY(-100%);
}

.search-active .navbar-search {
  transform: translateY(0);
}

/* Table */
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(255, 255, 255, 0.08);
}

.table th {
  font-size: 12px;
  text-transform: uppercase;
}
.table td:active {
  background-color: transparent;
}
.table.table-striped tr:nth-child(odd) td:active {
  background: #141c2f !important;
}

/* Template Custom CSS - Add your own CSS to this file
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.bg-icon {
  color: white;
  padding: 1rem;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  position: relative;
}
.bg-icon::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.bg-icon.bg-icon-primary {
  background-color: #186db7;
  color: #e6e6e6;
}
.bg-icon.bg-icon-primary.bg-icon-hover:hover {
  background-color: #125289;
  color: #cccccc;
}
.bg-icon.bg-icon-secondary {
  background-color: #4e535d;
  color: #e6e6e6;
}
.bg-icon.bg-icon-secondary.bg-icon-hover:hover {
  background-color: #3b3e46;
  color: #cccccc;
}
.bg-icon.bg-icon-success {
  background-color: #3d8c40;
  color: #e6e6e6;
}
.bg-icon.bg-icon-success.bg-icon-hover:hover {
  background-color: #2e6930;
  color: #cccccc;
}
.bg-icon.bg-icon-info {
  background-color: #0096aa;
  color: #e6e6e6;
}
.bg-icon.bg-icon-info.bg-icon-hover:hover {
  background-color: #00717f;
  color: #cccccc;
}
.bg-icon.bg-icon-warning {
  background-color: #cc9a06;
  color: #e6e6e6;
}
.bg-icon.bg-icon-warning.bg-icon-hover:hover {
  background-color: #997404;
  color: #cccccc;
}
.bg-icon.bg-icon-danger {
  background-color: #c3362b;
  color: #e6e6e6;
}
.bg-icon.bg-icon-danger.bg-icon-hover:hover {
  background-color: #922820;
  color: #cccccc;
}
.bg-icon.bg-icon-light {
  background-color: #63676f;
  color: #e6e6e6;
}
.bg-icon.bg-icon-light.bg-icon-hover:hover {
  background-color: #4a4d53;
  color: #cccccc;
}
.bg-icon.bg-icon-dark {
  background-color: #18212f;
  color: #e6e6e6;
}
.bg-icon.bg-icon-dark.bg-icon-hover:hover {
  background-color: #121923;
  color: #cccccc;
}
.bg-icon.bg-icon-facebook {
  background-color: #2f477a;
  color: #e6e6e6;
}
.bg-icon.bg-icon-facebook.bg-icon-hover:hover {
  background-color: #23355b;
  color: #cccccc;
}
.bg-icon.bg-icon-twitter {
  background-color: #008abe;
  color: #e6e6e6;
}
.bg-icon.bg-icon-twitter.bg-icon-hover:hover {
  background-color: #00678f;
  color: #cccccc;
}
.bg-icon.bg-icon-instagram {
  background-color: #b42656;
  color: #e6e6e6;
}
.bg-icon.bg-icon-instagram.bg-icon-hover:hover {
  background-color: #871d41;
  color: #cccccc;
}
.bg-icon.bg-icon-white {
  background-color: #cccccc;
  color: #e6e6e6;
}
.bg-icon.bg-icon-white.bg-icon-hover:hover {
  background-color: #999999;
  color: #cccccc;
}
.bg-icon.bg-icon-pink {
  background-color: #ba184f;
  color: #e6e6e6;
}
.bg-icon.bg-icon-pink.bg-icon-hover:hover {
  background-color: #8c123b;
  color: #cccccc;
}
.bg-icon.bg-icon-teal {
  background-color: #00786d;
  color: #e6e6e6;
}
.bg-icon.bg-icon-teal.bg-icon-hover:hover {
  background-color: #005a52;
  color: #cccccc;
}
.bg-icon.bg-icon-orange {
  background-color: #cc461b;
  color: #e6e6e6;
}
.bg-icon.bg-icon-orange.bg-icon-hover:hover {
  background-color: #993414;
  color: #cccccc;
}

.dashboard-block {
  position: relative;
}
.dashboard-block .dashboard-block-tools {
  position: absolute;
  z-index: 10;
  opacity: 0;
  transition: all 0.25s ease;
  right: 27px;
  top: 15px;
  padding: 2px 5px 0;
  border-radius: 3px;
}
.dashboard-block .dashboard-block-tools i {
  cursor: pointer;
}
.dashboard-block .dashboard-block-tool {
  margin: 0 5px;
  display: inline-block;
}
.dashboard-block .dashboard-block-tool i {
  color: #fff;
  opacity: 0.65;
  transition: all 0.25s ease;
}
.dashboard-block .dashboard-block-tool:hover {
  opacity: 1;
}
.dashboard-block:hover .dashboard-block-tools {
  opacity: 1;
}

.icon-field input {
  display: none;
}
.icon-field label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 3px;
  background-color: #141c2f;
  height: 100%;
  border: 1px solid #141c2f;
  transition: all 0.25s ease;
}
.icon-field input:checked + label {
  border: 1px solid #1e88e5;
}

.input-group .clr-field {
  display: block;
  flex-grow: 1;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  overflow: hidden;
}
.input-group .clr-field input {
  border-radius: 0;
}
.input-group .clr-field button {
  width: 48px;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  border-left: 1px solid #484e5d;
  right: 1px;
}