/* generic site wide styles*/
body {
    overflow-x: hidden;
    min-height: 100vh;
}

*:focus,
*:active {
    outline: none !important;
    box-shadow: none !important;
}

#main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: calc(var(--theme-navbar-height) + 1.5rem);
    width: calc(100% - var(--theme-sidebar-width));
    margin-left: var(--theme-sidebar-width);
    overflow-x: hidden;
}

#main > .container-fluid {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
}

.disable-child-pointer * {
    pointer-events: none;
}


/* handle menu hide/show for smaller screens */
.menu-hidden {
    #main {
        width: 100%;
        margin-left: 0;
    }
}

/* handle aside transitions */
.has-transition {
    #main {
        transition: all ease-in .2s;
    }
}
