// faded background
@each $color, $value in $theme-colors {
    .bg-#{$color}-faded {
      @include bg-variant-faded(rgba($value, $btn-faded-bg-opacity));
    }
}

/* custom bg colours */
.bg-dark-700 {
  background: $gray-700 !important;
}
.bg-dark-800 {
  background: $gray-800 !important;
}
.bg-dark-900 {
  background: $gray-900 !important;
}
.bg-dark-opacity {
  background: var(--theme-bg-dark-opacity);
}

.carousel-item {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, transparent 0%, transparentize(black, .65) 50%);
        z-index: 10;
        pointer-events: none;
    }

    .carousel-caption, .carousel-control-prev, .carousel-control-next, .carousel-indicators {
        z-index: 20;
    }
}
